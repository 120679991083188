import { ArtistDao, EventDao, FestivalDao, VenueDao } from "../src/DAO";
import React, { useEffect, useRef, useState } from "react";
import { getCookie, setCookies } from "cookies-next";

import { ActivityIndicator } from "react-native-web";
import { CardList } from "../src/components/molecules";
import { Constants } from "../src/config";
import Footer from "../components_web/Footer";
import Header from "../components_web/Header";
import Image from "next/image";
import Link from "next/link";
import { NextSeo } from "next-seo";
import SideMenu from "../components_web/SideMenu";
import { getClass } from "../src/helpers/formatHelpers";
import moment from "moment";
import { nFormatter } from "../helpers";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";

const isServer = typeof window === "undefined";
const WOW = !isServer ? require("wow.js") : null;

export async function getServerSideProps(context) {
  try {
    const currentHourIndex = parseInt(
      moment.utc(moment.utc().format()).local().format("HH")
    );
    const backgroundImage =
      process.env.NEXT_PUBLIC_APP_NAME === "Beachful" ||
      process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
      process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
      process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
      process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
      process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
      process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
      process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
      process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
      process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub"
        ? `https://ik.imagekit.io/zrbvu2w3h/s3/landing/${process.env.NEXT_PUBLIC_APP_NAME?.replace(
            " ",
            ""
          )}_Landing.jpg`
        : `https://ik.imagekit.io/zrbvu2w3h/s3/landing/${
            process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
            process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia"
              ? `${process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")}_`
              : ""
          }${currentHourIndex === 0 ? 24 : currentHourIndex}_Landing.jpg`;
    const backgroundImageMobile =
      process.env.NEXT_PUBLIC_APP_NAME === "Beachful" ||
      process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
      process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
      process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
      process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
      process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
      process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
      process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
      process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
      process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub"
        ? `https://ik.imagekit.io/zrbvu2w3h/s3/landing/${process.env.NEXT_PUBLIC_APP_NAME?.replace(
            " ",
            ""
          )}_Landing_Mobile.jpg`
        : `https://ik.imagekit.io/zrbvu2w3h/s3/landing/${
            process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
            process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia"
              ? `${process.env.NEXT_PUBLIC_APP_NAME?.replace(" ", "")}_`
              : ""
          }${
            currentHourIndex === 0 ? 24 : currentHourIndex
          }_Landing_Mobile.jpg`;

    return { props: { backgroundImage, backgroundImageMobile } };
  } catch (err) {
    console.error("err", err);
    return {
      notFound: true,
    };
  }
}

const Landing = (props) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { backgroundImage, backgroundImageMobile } = props;
  const flatListFestivalRef = useRef(null);
  let pageFestival = 0;
  const flatListEventRef = useRef(null);
  let pageEvent = 0;
  const flatListArtistRef = useRef(null);
  let pageArtist = 0;
  const flatListVenueRef = useRef(null);
  let pageVenue = 0;
  const flatListRooftopRef = useRef(null);
  let pageRooftop = 0;
  const flatListSpaRef = useRef(null);
  let pageSpa = 0;
  const flatListVenueBeachfulRef = useRef(null);
  let pageVenueBeachful = 0;
  const flatListPrefabCompanyRef = useRef(null);
  let pagePrefabCompany = 0;
  const flatListPrefabHomeRef = useRef(null);
  let pagePrefabHome = 0;
  const flatListZooRef = useRef(null);
  let pageZoo = 0;
  const flatListAnimalRef = useRef(null);
  let pageAnimal = 0;
  const flatListParkfulRef = useRef(null);
  let pageParkful = 0;
  const flatListCasinoRef = useRef(null);
  let pageCasino = 0;
  const flatListCastleRef = useRef(null);
  let pageCastle = 0;
  const flatListResortRef = useRef(null);
  let pageResort = 0;
  const flatListAparthotelRef = useRef(null);
  let pageAparthotel = 0;
  const flatListVenueCocktailRef = useRef(null);
  let pageVenueCocktail = 0;
  const [showTagline, setShowTagline] = useState(true);
  const [sideMenuShow, setSideMenuShow] = useState(false);
  const [sideMenuHeaderShow, setSideMenuHeaderShow] = useState(false);
  const [keyword, setKeyword] = useState("");

  const [festivalList, setFestivalList] = useState([]);
  const [festivalSort, setFestivalSort] = useState(
    Constants.sortByAction.SORT_BY_POPULARITY
  );
  const [isLoadingFestival, setIsLoadingFestival] = useState(false);

  const [eventList, setEventList] = useState([]);
  const [eventSort, setEventSort] = useState(
    Constants.sortByAction.SORT_BY_POPULARITY
  );
  const [isLoadingEvent, setIsLoadingEvent] = useState(false);

  const [artistList, setArtistList] = useState([]);
  const [artistSort, setArtistSort] = useState(
    Constants.sortByAction.SORT_BY_POPULARITY
  );
  const [isLoadingArtist, setIsLoadingArtist] = useState(false);

  const [venueList, setVenueList] = useState([]);
  const [venueSort, setVenueSort] = useState(
    Constants.sortByAction.SORT_BY_RECOMMENDED
  );
  const [isLoadingVenue, setIsLoadingVenue] = useState(false);

  const [rooftopList, setRooftopList] = useState([]);
  const [rooftopSort, setRooftopSort] = useState(
    Constants.sortByAction.SORT_BY_RECOMMENDED
  );
  const [isLoadingRooftop, setIsLoadingRooftop] = useState(false);

  const [spaList, setSpaList] = useState([]);
  const [spaSort, setSpaSort] = useState(
    Constants.sortByAction.SORT_BY_RECOMMENDED
  );
  const [isLoadingSpa, setIsLoadingSpa] = useState(false);

  const [venueBeachfulList, setVenueBeachfulList] = useState([]);
  const [venueBeachfulSort, setVenueBeachfulSort] = useState(
    Constants.sortByAction.SORT_BY_RECOMMENDED
  );
  const [isLoadingVenueBeachful, setIsLoadingVenueBeachful] = useState(false);

  const [prefabCompanyList, setPrefabCompanyList] = useState([]);
  const [prefabCompanySort, setPrefabCompanySort] = useState(
    Constants.sortByAction.SORT_BY_RECOMMENDED
  );
  const [isLoadingPrefabCompany, setIsLoadingPrefabCompany] = useState(false);

  const [prefabHomeList, setPrefabHomeList] = useState([]);
  const [prefabHomeSort, setPrefabHomeSort] = useState(
    Constants.sortByAction.SORT_BY_RECOMMENDED
  );
  const [isLoadingPrefabHome, setIsLoadingPrefabHome] = useState(false);

  const [zooList, setZooList] = useState([]);
  const [zooSort, setZooSort] = useState(
    Constants.sortByAction.SORT_BY_RECOMMENDED
  );
  const [isLoadingZoo, setIsLoadingZoo] = useState(false);

  const [animalList, setAnimalList] = useState([]);
  const [animalSort, setAnimalSort] = useState(
    Constants.sortByAction.SORT_BY_RECOMMENDED
  );
  const [isLoadingAnimal, setIsLoadingAnimal] = useState(false);

  const [parkfulList, setParkfulList] = useState([]);
  const [parkfulSort, setParkfulSort] = useState(
    Constants.sortByAction.SORT_BY_RECOMMENDED
  );
  const [isLoadingParkful, setIsLoadingParkful] = useState(false);

  const [casinoList, setCasinoList] = useState([]);
  const [casinoSort, setCasinoSort] = useState(
    Constants.sortByAction.SORT_BY_RECOMMENDED
  );
  const [isLoadingCasino, setIsLoadingCasino] = useState(false);

  const [castleList, setCastleList] = useState([]);
  const [castleSort, setCastleSort] = useState(
    Constants.sortByAction.SORT_BY_RECOMMENDED
  );
  const [isLoadingCastle, setIsLoadingCastle] = useState(false);

  const [resortList, setResortList] = useState([]);
  const [resortSort, setResortSort] = useState(
    Constants.sortByAction.SORT_BY_RECOMMENDED
  );
  const [isLoadingResort, setIsLoadingResort] = useState(false);

  const [aparthotelList, setAparthotelList] = useState([]);
  const [aparthotelSort, setAparthotelSort] = useState(
    Constants.sortByAction.SORT_BY_RECOMMENDED
  );
  const [isLoadingAparthotel, setIsLoadingAparthotel] = useState(false);

  const [venueCocktailList, setVenueCocktailList] = useState([]);
  const [venueCocktailSort, setVenueCocktailSort] = useState(
    Constants.sortByAction.SORT_BY_RECOMMENDED
  );
  const [isLoadingVenueCocktail, setIsLoadingVenueCocktail] = useState(false);

  const [festivalCount, setFestivalCount] = useState("0");
  const [eventCount, setEventCount] = useState("0");
  const [artistCount, setArtistCount] = useState("0");
  const [venueCount, setVenueCount] = useState("0");
  const [rooftopCount, setRooftopCount] = useState("0");
  const [spaCount, setSpaCount] = useState("0");
  const [venueBeachfulCount, setVenueBeachfulCount] = useState("0");
  const [prefabCompanyCount, setPrefabCompanyCount] = useState("0");
  const [prefabHomeCount, setPrefabHomeCount] = useState("0");
  const [zooCount, setZooCount] = useState("0");
  const [animalCount, setAnimalCount] = useState("0");
  const [parkfulCount, setParkfulCount] = useState("0");
  const [casinoCount, setCasinoCount] = useState("0");
  const [castleCount, setCastleCount] = useState("0");
  const [resortCount, setResortCount] = useState("0");
  const [aparthotelCount, setAparthotelCount] = useState("0");
  const [venueCocktailCount, setVenueCocktailCount] = useState("0");

  const loadFestival = () => {
    setIsLoadingFestival(true);
    if (getCookie("festival_total")) {
    } else {
      setFestivalCount(getCookie("festival_total"));
    }

    FestivalDao.findWeb({
      pageSize: 1,
    }).then((result) => {
      setFestivalCount(`+${nFormatter(result.totalCount)}`);
      setCookies("festival_total", `+${nFormatter(result.totalCount)}`, {
        maxAge: 86400000,
      }); //expired 1 day in milliseconds
    });

    FestivalDao.findWeb(
      festivalSort === Constants.sortByAction.SORT_BY_TRENDING
        ? {
            sortType: Constants.sortByAction.SORT_BY_TRENDING,
            pageSize: 15,
            dateSortType: Constants.dateFilterType.DATE_FILTER_UPCOMING,
            startDateFrom: moment().format("YYYY-MM-DD"),
            startDateTo: moment().add(1, "month").format("YYYY-MM-DD"),
          }
        : {
            sortType: Constants.sortByAction.SORT_BY_POPULARITY,
            pageSize: 15,
            dateSortType: Constants.dateFilterType.DATE_FILTER_UPCOMING,
          }
    ).then((result) => {
      setIsLoadingFestival(false);
      setFestivalList(result.data);
    });
  };

  const loadEvent = () => {
    setIsLoadingEvent(true);
    if (getCookie("festival_total")) {
    } else {
      setEventCount(getCookie("festival_total"));
    }

    EventDao.findWeb({
      pageSize: 1,
      categoryType: Constants.categoryType.CATEGORY_EVENT,
    }).then((result) => {
      setEventCount(`+${nFormatter(result.totalCount)}`);
      setCookies("event_total", `+${nFormatter(result.totalCount)}`, {
        maxAge: 86400000,
      }); //expired 1 day in milliseconds
    });

    EventDao.findWeb(
      eventSort === Constants.sortByAction.SORT_BY_TRENDING
        ? {
            sortType: Constants.sortByAction.SORT_BY_TRENDING,
            pageSize: 15,
            dateSortType: Constants.dateFilterType.DATE_FILTER_UPCOMING,
            startDateFrom: moment().format("YYYY-MM-DD"),
            startDateTo: moment().add(1, "month").format("YYYY-MM-DD"),
            categoryType: Constants.categoryType.CATEGORY_EVENT,
          }
        : {
            sortType: Constants.sortByAction.SORT_BY_POPULARITY,
            pageSize: 15,
            dateSortType: Constants.dateFilterType.DATE_FILTER_UPCOMING,
            categoryType: Constants.categoryType.CATEGORY_EVENT,
          }
    ).then((result) => {
      setIsLoadingEvent(false);
      setEventList(result.data);
    });
  };

  const loadArtist = () => {
    setIsLoadingArtist(true);
    if (getCookie("artist_total")) {
      setArtistCount(getCookie("artist_total"));
    }
    const params = {
      sortType: artistSort,
      pageSize: 15,
    };
    if (artistSort == Constants.sortByAction.SORT_BY_TRENDING) {
      params.startDateFrom = moment().format("YYYY-MM-DD");
      params.startDateTo = moment().add(1, "month").format("YYYY-MM-DD");
    }

    ArtistDao.findWeb({
      pageSize: 1,
    }).then((result) => {
      setArtistCount(`+${nFormatter(result.totalCount)}`);
      setCookies("artist_total", `+${nFormatter(result.totalCount)}`, {
        maxAge: 86400000,
      }); //expired 1 day in milliseconds
    });
    ArtistDao.findWeb(params).then((result) => {
      setIsLoadingArtist(false);
      setArtistList(result.data);
    });
  };

  const loadVenue = () => {
    setIsLoadingVenue(true);
    if (getCookie("venue_total")) {
    } else {
      setVenueCount(getCookie("venue_total"));
    }

    VenueDao.find({
      pageSize: 1,
    }).then((result) => {
      setVenueCount(`+${nFormatter(result.totalCount)}`);
      setCookies("venue_total", `+${nFormatter(result.totalCount)}`, {
        maxAge: 86400000,
      }); //expired 1 day in milliseconds
    });

    VenueDao.find({
      sortType: venueSort,
      pageSize: 15,
      venueStatus: [
        Constants.venueStatus.FE_STAT_OPEN,
        Constants.venueStatus.FE_STAT_CLOSE,
        Constants.venueStatus.FE_STAT_TEMPORARILY_CLOSED,
      ],
    }).then((result) => {
      setIsLoadingVenue(false);
      setVenueList(result.data);
    });
  };

  const loadRooftop = () => {
    setIsLoadingRooftop(true);
    if (getCookie("rooftop_total")) {
    } else {
      setRooftopCount(getCookie("rooftop_total"));
    }

    VenueDao.find({
      pageSize: 1,
      categoryType: Constants.categoryType.CATEGORY_ROOFTOP,
    }).then((result) => {
      setRooftopCount(`+${nFormatter(result.totalCount)}`);
      setCookies("rooftop_total", `+${nFormatter(result.totalCount)}`, {
        maxAge: 86400000,
      }); //expired 1 day in milliseconds
    });

    VenueDao.find({
      sortType: rooftopSort,
      pageSize: 15,
      venueStatus: [
        Constants.venueStatus.FE_STAT_OPEN,
        Constants.venueStatus.FE_STAT_CLOSE,
        Constants.venueStatus.FE_STAT_TEMPORARILY_CLOSED,
      ],
      categoryType: Constants.categoryType.CATEGORY_ROOFTOP,
    }).then((result) => {
      setIsLoadingRooftop(false);
      setRooftopList(result.data);
    });
  };

  const loadSpa = () => {
    setIsLoadingSpa(true);
    if (getCookie("spa_total")) {
    } else {
      setSpaCount(getCookie("spa_total"));
    }

    VenueDao.find({
      pageSize: 1,
      categoryType: Constants.categoryType.CATEGORY_SPA,
    }).then((result) => {
      setSpaCount(`+${nFormatter(result.totalCount)}`);
      setCookies("spa_total", `+${nFormatter(result.totalCount)}`, {
        maxAge: 86400000,
      }); //expired 1 day in milliseconds
    });

    VenueDao.find({
      sortType: spaSort,
      pageSize: 15,
      venueStatus: [
        Constants.venueStatus.FE_STAT_OPEN,
        Constants.venueStatus.FE_STAT_CLOSE,
        Constants.venueStatus.FE_STAT_TEMPORARILY_CLOSED,
      ],
      categoryType: Constants.categoryType.CATEGORY_SPA,
    }).then((result) => {
      setIsLoadingSpa(false);
      setSpaList(result.data);
    });
  };

  const loadVenueBeachful = () => {
    setIsLoadingVenueBeachful(true);
    if (getCookie("venue_beachful_total")) {
    } else {
      setVenueBeachfulCount(getCookie("venue_beachful_total"));
    }

    VenueDao.find({
      pageSize: 1,
      categoryType: Constants.categoryType.CATEGORY_VENUE_BEACHFUL,
    }).then((result) => {
      setVenueBeachfulCount(`+${nFormatter(result.totalCount)}`);
      setCookies("venue_beachful_total", `+${nFormatter(result.totalCount)}`, {
        maxAge: 86400000,
      }); //expired 1 day in milliseconds
    });

    VenueDao.find({
      sortType: venueBeachfulSort,
      pageSize: 15,
      venueStatus: [
        Constants.venueStatus.FE_STAT_OPEN,
        Constants.venueStatus.FE_STAT_CLOSE,
        Constants.venueStatus.FE_STAT_TEMPORARILY_CLOSED,
      ],
      categoryType: Constants.categoryType.CATEGORY_VENUE_BEACHFUL,
    }).then((result) => {
      setIsLoadingVenueBeachful(false);
      setVenueBeachfulList(result.data);
    });
  };

  const loadPrefabCompany = () => {
    setIsLoadingPrefabCompany(true);
    if (getCookie("prefab_company_total")) {
    } else {
      setPrefabCompanyCount(getCookie("prefab_company_total"));
    }

    VenueDao.find({
      pageSize: 1,
      categoryType: Constants.categoryType.CATEGORY_PREFAB_COMPANY,
    }).then((result) => {
      setPrefabCompanyCount(`+${nFormatter(result.totalCount)}`);
      setCookies("prefab_company_total", `+${nFormatter(result.totalCount)}`, {
        maxAge: 86400000,
      }); //expired 1 day in milliseconds
    });

    VenueDao.find({
      sortType: prefabCompanySort,
      pageSize: 15,
      categoryType: Constants.categoryType.CATEGORY_PREFAB_COMPANY,
    }).then((result) => {
      setIsLoadingPrefabCompany(false);
      setPrefabCompanyList(result.data);
    });
  };

  const loadPrefabHome = () => {
    setIsLoadingPrefabHome(true);
    if (getCookie("prefab_home_total")) {
    } else {
      setPrefabHomeCount(getCookie("prefab_home_total"));
    }

    VenueDao.find({
      pageSize: 1,
      categoryType: Constants.categoryType.CATEGORY_PREFAB_HOMES,
    }).then((result) => {
      setPrefabHomeCount(`+${nFormatter(result.totalCount)}`);
      setCookies("prefab_home_total", `+${nFormatter(result.totalCount)}`, {
        maxAge: 86400000,
      }); //expired 1 day in milliseconds
    });

    VenueDao.find({
      sortType: prefabHomeSort,
      pageSize: 15,
      categoryType: Constants.categoryType.CATEGORY_PREFAB_HOMES,
    }).then((result) => {
      setIsLoadingPrefabHome(false);
      setPrefabHomeList(result.data);
    });
  };

  const loadZoo = () => {
    setIsLoadingZoo(true);
    if (getCookie("zoo_total")) {
    } else {
      setZooCount(getCookie("zoo_total"));
    }

    VenueDao.find({
      pageSize: 1,
      categoryType: Constants.categoryType.CATEGORY_ZOO,
    }).then((result) => {
      setZooCount(`+${nFormatter(result.totalCount)}`);
      setCookies("zoo_total", `+${nFormatter(result.totalCount)}`, {
        maxAge: 86400000,
      }); //expired 1 day in milliseconds
    });

    VenueDao.find({
      sortType: zooSort,
      pageSize: 15,
      categoryType: Constants.categoryType.CATEGORY_ZOO,
    }).then((result) => {
      setIsLoadingZoo(false);
      setZooList(result.data);
    });
  };

  const loadAnimal = () => {
    setIsLoadingAnimal(true);
    if (getCookie("animal_total")) {
    } else {
      setAnimalCount(getCookie("animal_total"));
    }

    VenueDao.find({
      pageSize: 1,
      categoryType: Constants.categoryType.CATEGORY_ANIMAL,
    }).then((result) => {
      setAnimalCount(`+${nFormatter(result.totalCount)}`);
      setCookies("animal_total", `+${nFormatter(result.totalCount)}`, {
        maxAge: 86400000,
      }); //expired 1 day in milliseconds
    });

    VenueDao.find({
      sortType: animalSort,
      pageSize: 15,
      categoryType: Constants.categoryType.CATEGORY_ANIMAL,
    }).then((result) => {
      setIsLoadingAnimal(false);
      setAnimalList(result.data);
    });
  };

  const loadParkful = () => {
    setIsLoadingParkful(true);
    if (getCookie("parkful_total")) {
    } else {
      setParkfulCount(getCookie("parkful_total"));
    }

    VenueDao.find({
      pageSize: 1,
      categoryType: Constants.categoryType.CATEGORY_PARKFUL,
    }).then((result) => {
      setParkfulCount(`+${nFormatter(result.totalCount)}`);
      setCookies("parkful_total", `+${nFormatter(result.totalCount)}`, {
        maxAge: 86400000,
      }); //expired 1 day in milliseconds
    });

    VenueDao.find({
      sortType: parkfulSort,
      pageSize: 15,
      categoryType: Constants.categoryType.CATEGORY_PARKFUL,
    }).then((result) => {
      setIsLoadingParkful(false);
      setParkfulList(result.data);
    });
  };

  const loadCasino = () => {
    setIsLoadingCasino(true);
    if (getCookie("casino_total")) {
    } else {
      setCasinoCount(getCookie("casino_total"));
    }

    VenueDao.find({
      pageSize: 1,
      categoryType: Constants.categoryType.CATEGORY_CASINO,
    }).then((result) => {
      setCasinoCount(`+${nFormatter(result.totalCount)}`);
      setCookies("casino_total", `+${nFormatter(result.totalCount)}`, {
        maxAge: 86400000,
      }); //expired 1 day in milliseconds
    });

    VenueDao.find({
      sortType: casinoSort,
      pageSize: 15,
      categoryType: Constants.categoryType.CATEGORY_CASINO,
    }).then((result) => {
      setIsLoadingCasino(false);
      setCasinoList(result.data);
    });
  };

  const loadCastle = () => {
    setIsLoadingCastle(true);
    if (getCookie("castle_total")) {
    } else {
      setCastleCount(getCookie("castle_total"));
    }

    VenueDao.find({
      pageSize: 1,
      categoryType: Constants.categoryType.CATEGORY_CASTLE,
    }).then((result) => {
      setCastleCount(`+${nFormatter(result.totalCount)}`);
      setCookies("castle_total", `+${nFormatter(result.totalCount)}`, {
        maxAge: 86400000,
      }); //expired 1 day in milliseconds
    });

    VenueDao.find({
      sortType: castleSort,
      pageSize: 15,
      categoryType: Constants.categoryType.CATEGORY_CASTLE,
    }).then((result) => {
      setIsLoadingCastle(false);
      setCastleList(result.data);
    });
  };

  const loadResort = () => {
    setIsLoadingResort(true);
    if (getCookie("resort_total")) {
    } else {
      setResortCount(getCookie("resort_total"));
    }

    VenueDao.find({
      pageSize: 1,
      categoryType: Constants.categoryType.CATEGORY_RESORT,
    }).then((result) => {
      setResortCount(`+${nFormatter(result.totalCount)}`);
      setCookies("resort_total", `+${nFormatter(result.totalCount)}`, {
        maxAge: 86400000,
      }); //expired 1 day in milliseconds
    });

    VenueDao.find({
      sortType: resortSort,
      pageSize: 15,
      categoryType: Constants.categoryType.CATEGORY_RESORT,
    }).then((result) => {
      setIsLoadingResort(false);
      setResortList(result.data);
    });
  };

  const loadAparthotel = () => {
    setIsLoadingAparthotel(true);
    if (getCookie("aparthotel_total")) {
    } else {
      setAparthotelCount(getCookie("aparthotel_total"));
    }

    VenueDao.find({
      pageSize: 1,
      categoryType: Constants.categoryType.CATEGORY_APARTHOTEL,
    }).then((result) => {
      setAparthotelCount(`+${nFormatter(result.totalCount)}`);
      setCookies("aparthotel_total", `+${nFormatter(result.totalCount)}`, {
        maxAge: 86400000,
      }); //expired 1 day in milliseconds
    });

    VenueDao.find({
      sortType: aparthotelSort,
      pageSize: 15,
      categoryType: Constants.categoryType.CATEGORY_APARTHOTEL,
    }).then((result) => {
      setIsLoadingAparthotel(false);
      setAparthotelList(result.data);
    });
  };

  const loadVenueCocktail = () => {
    setIsLoadingVenueCocktail(true);
    if (getCookie("venue_cocktail_total")) {
    } else {
      setVenueCocktailCount(getCookie("venue_cocktail_total"));
    }

    VenueDao.find({
      pageSize: 1,
      categoryType: Constants.categoryType.CATEGORY_VENUE_COCKTAIL,
    }).then((result) => {
      setVenueCocktailCount(`+${nFormatter(result.totalCount)}`);
      setCookies("venue_cocktail_total", `+${nFormatter(result.totalCount)}`, {
        maxAge: 86400000,
      }); //expired 1 day in milliseconds
    });

    VenueDao.find({
      sortType: venueCocktailSort,
      pageSize: 15,
      venueStatus: [
        Constants.venueStatus.FE_STAT_OPEN,
        Constants.venueStatus.FE_STAT_CLOSE,
        Constants.venueStatus.FE_STAT_TEMPORARILY_CLOSED,
      ],
      categoryType: Constants.categoryType.CATEGORY_VENUE_COCKTAIL,
    }).then((result) => {
      setIsLoadingVenueCocktail(false);
      setVenueCocktailList(result.data);
    });
  };

  useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
        (i) => i === "Festivals"
      )
    ) {
      loadFestival();
    }
  }, [festivalSort]);

  useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
        (i) => i === "Artists"
      )
    ) {
      loadArtist();
    }
  }, [artistSort]);

  useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
        (i) => i === "Venues"
      )
    ) {
      loadVenue();
    }
  }, [venueSort]);

  useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
        (i) => i === "Rooftops"
      )
    ) {
      loadRooftop();
    }
  }, [rooftopSort]);

  useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
        (i) => i === "Spas"
      )
    ) {
      loadSpa();
    }
  }, [spaSort]);

  useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
        (i) => i === "Venue Beachful"
      )
    ) {
      loadVenueBeachful();
    }
  }, [venueBeachfulSort]);

  useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
        (i) => i === "Prefab Company"
      )
    ) {
      loadPrefabCompany();
    }
  }, [prefabCompanySort]);

  useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
        (i) => i === "Prefab Home"
      )
    ) {
      loadPrefabHome();
    }
  }, [prefabHomeSort]);

  useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
        (i) => i === "Zoos"
      )
    ) {
      loadZoo();
    }
  }, [zooSort]);

  useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
        (i) => i === "Animals"
      )
    ) {
      loadAnimal();
    }
  }, [animalSort]);

  useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
        (i) => i === "Parks"
      )
    ) {
      loadParkful();
    }
  }, [parkfulSort]);

  useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
        (i) => i === "Casinos"
      )
    ) {
      loadCasino();
    }
  }, [casinoSort]);

  useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
        (i) => i === "Castles"
      )
    ) {
      loadCastle();
    }
  }, [castleSort]);

  useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
        (i) => i === "Resorts"
      )
    ) {
      loadResort();
    }
  }, [resortSort]);

  useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
        (i) => i === "Aparthotels"
      )
    ) {
      loadAparthotel();
    }
  }, [aparthotelSort]);

  useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
        (i) => i === "Venue Cocktail"
      )
    ) {
      loadVenueCocktail();
    }
  }, [venueCocktailSort]);

  useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
        (i) => i === "Events"
      )
    ) {
      loadEvent();
    }
  }, [eventSort]);

  useEffect(() => {
    new WOW().init();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setShowTagline(false);
    }, 2000);
  }, []);

  return (
    <div className="sm:h-auto sm:block flex flex-col h-screen overflow-hidden">
      <NextSeo
        title={`${process.env.NEXT_PUBLIC_APP_NAME} - ${process.env.NEXT_PUBLIC_APP_SLOGAN}`}
        description={process.env.NEXT_PUBLIC_APP_DESC}
        canonical={`https://${process.env.NEXT_PUBLIC_WWW_BASE_URL}`}
        openGraph={{
          type: "website",
          locale: "en_EN",
          url: `https://${process.env.NEXT_PUBLIC_WWW_BASE_URL}`,
          title: `${process.env.NEXT_PUBLIC_APP_NAME} - ${process.env.NEXT_PUBLIC_APP_SLOGAN}`,
          description: process.env.NEXT_PUBLIC_APP_DESC,
          images: [
            {
              url: `https://${process.env.NEXT_PUBLIC_WWW_BASE_URL}${process.env.NEXT_PUBLIC_BANNER_IMAGE}`,
              width: 1200,
              height: 630,
              alt: `${process.env.NEXT_PUBLIC_APP_NAME} - ${process.env.NEXT_PUBLIC_APP_SLOGAN}`,
              type: "image/png",
            },
          ],
          siteName: `${process.env.NEXT_PUBLIC_APP_NAME} - ${process.env.NEXT_PUBLIC_APP_SLOGAN}`,
        }}
      />
      <Header setSideMenuShow={setSideMenuShow} />

      <SideMenu
        sideMenuShow={sideMenuShow}
        setSideMenuShow={setSideMenuShow}
        sideMenuHeaderShow={sideMenuHeaderShow}
        setSideMenuHeaderShow={setSideMenuHeaderShow}
      />

      {/* Hero Section Mobile */}
      <div
        id="home"
        style={{
          // backgroundImage: "url(/images/landing_bg_mobile.jpg)",
          backgroundImage: `url(${backgroundImageMobile})`,
          backgroundSize: "cover",
          backgroundPosition: "bottom center",
          backgroundColor: "black",
        }}
        className="sm:hidden block sm:h-auto flex flex-1">
        <div
          className={`w-full h-full flex flex-1 ${getClass(
            "bg-black6",
            true
          )}`}>
          <div className="wrapper sm:block flex flex-col justify-between mx-auto relative md:h-800px md:py-0 sm:py-20 py-0">
            {/* <div className="flex sm:flex-col flex-row justify-center items-center absolute sm:pt-304px pt-0 sm:top-0 sm:bottom-auto bottom-10 sm:left-auto left-0 sm:right-auto right-0">
              <a
                className="inline-block sm:pb-30px pb-0 sm:mr-0 mr-60px"
                href="https://play.google.com/store/apps/details?id=com.soundclub"
                target="_blank"
                rel="noreferrer">
                <CImage
                  source="/icons/ic_googleplay.svg"
                  width={25}
                  height={25}
                  layout="fixed"
                  alt="googleplay"
                />
              </a>
              <a
                className="inline-block py-0"
                href="https://apps.apple.com/us/app/soundclub-discover-festivals/id1625045664"
                target="_blank"
                rel="noreferrer">
                <CImage
                  source="/icons/ic_appstore.svg"
                  width={25}
                  height={25}
                  layout="fixed"
                  alt="appstore"
                />
              </a>
            </div> */}
            <div className="flex flex-col justify-center items-center flex-1">
              <h1 className="hidden">
                {process.env.NEXT_PUBLIC_APP_NAME}
                {" - "}
                {process.env.NEXT_PUBLIC_APP_SLOGAN}
              </h1>
              <div
                data-wow-delay="1.5s"
                data-wow-iteration="1"
                className={`wow fadeOut sm:h-45px h-29px flex flex-row flex-wrap items-center justify-center md:text-40px text-2xl font-bold text-white text-center sm:mb-146px mb-0 ${
                  showTagline ? "" : "opacity-0"
                }`}>
                <div className="wow fadeInDown">
                  {process.env.NEXT_PUBLIC_APP_SLOGAN}
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center sm:h-full sm:pb-73px mb-0 md:pt-128px sm:pt-20 pt-0">
              <div
                className={`${getClass(
                  "bg-grayBackground3",
                  true
                )} rounded-full sm:flex hidden flex-row items-center justify-between h-50px md:w-670px w-full mb-98px`}>
                <div className="flex flex-row items-center">
                  <div
                    className={`flex flex-row justify-center items-center ${getClass(
                      "btn-gradient"
                    )} h-50px w-100px -mr-1 rounded-full cursor-pointer`}
                    onClick={() =>
                      router.push(
                        `/explore/${process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(
                          ","
                        )[0]
                          ?.toLowerCase()
                          ?.replace("venue cocktail", "bars")
                          ?.replace("venue beachful", "beach-clubs")
                          ?.replace("prefab company", "companies")
                          ?.replace("prefab home", "homes")}?search=${keyword}`
                      )
                    }>
                    <img src="/icons/ic_search.svg" alt="search" />
                  </div>
                  <input
                    className={`bg-transparent font-regular md:text-lg text-sm ${getClass(
                      "text-white",
                      true
                    )} ${getClass(
                      "placeholder-input"
                    )} md:ml-8 ml-4 outline-0 w-400px`}
                    placeholder={process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(
                      ","
                    )
                      .join(" | ")
                      ?.replace("Festivals", "Festival")
                      ?.replace("Artists", "Artist")
                      ?.replace("Venues", "Venue")
                      ?.replace("Rooftops", "Rooftop")
                      ?.replace("Spas", "Spa")
                      ?.replace("Venue Cocktail", "Bar")
                      ?.replace("Venue Beachful", "Beach Club")
                      ?.replace("Prefab Company", "Company")
                      ?.replace("Prefab Home", "Home")
                      ?.replace("Parks", "Park")
                      ?.replace("Casinos", "Casino")
                      ?.replace("Castles", "Castle")
                      ?.replace("Resorts", "Resort")
                      ?.replace("Aparthotels", "Aparthotel")}
                    onChange={(e) => setKeyword(e.target.value)}
                    value={keyword}
                    onKeyDown={(e) =>
                      e.key === "Enter"
                        ? router.push(
                            `/explore/${process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(
                              ","
                            )[0]
                              ?.toLowerCase()
                              ?.replace("venue cocktail", "bars")
                              ?.replace("venue beachful", "beach-clubs")
                              ?.replace("prefab company", "companies")
                              ?.replace(
                                "prefab home",
                                "homes"
                              )}?search=${keyword}`
                          )
                        : null
                    }
                  />
                </div>
                <div
                  className={`flex flex-row justify-center items-center ${getClass(
                    "btn-gradient"
                  )} h-50px -mr-1 w-100px w-full rounded-full cursor-pointer`}
                  onClick={() =>
                    router.push(
                      `/explore/${process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(
                        ","
                      )[0]
                        ?.toLowerCase()
                        ?.replace("venue cocktail", "bars")
                        ?.replace("venue beachful", "beach-clubs")
                        ?.replace("prefab company", "companies")
                        ?.replace("prefab home", "homes")}`
                    )
                  }>
                  <img src="/icons/ic_filter.svg" alt="filter" />
                </div>
              </div>

              <Link
                href={`/explore/${process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(
                  ","
                )[0]
                  ?.toLowerCase()
                  ?.replace("venue cocktail", "bars")
                  ?.replace("venue beachful", "beach-clubs")
                  ?.replace("prefab company", "companies")
                  ?.replace("prefab home", "homes")}`}>
                <a
                  className={`sm:hidden block w-190px sm:mt-80px mt-0 mb-[20px] flex flex-row justify-center items-center ${getClass(
                    "btn-gradient"
                  )} py-1 px-6 rounded-full cursor-pointer uppercase font-bold text-white`}>
                  Explore
                </a>
              </Link>
              <Link href={`/guides`}>
                <a
                  className={`sm:hidden block w-190px sm:mt-80px mt-0 mb-60px flex flex-row justify-center items-center ${getClass(
                    "btn-gradient"
                  )} py-1 px-6 rounded-full cursor-pointer uppercase font-bold text-white`}>
                  Guides
                </a>
              </Link>
              <div className="relative">
                <div className="flex flex-row items-center mb-20px px-3">
                  <div
                    className={`${getClass(
                      "bg-gradient1",
                      true
                    )} h-2px rounded-full w-full`}
                  />
                  <div className="md:text-2xl text-lg font-bold text-white uppercase text-center mx-4 relative z-5">
                    {t("key_web_discover")}
                  </div>
                  <div
                    className={`${getClass(
                      "bg-gradient1",
                      true
                    )} h-2px rounded-full w-full`}
                  />
                </div>
                <div className="flex flex-row items-center">
                  {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
                    (i) => i === "Venues"
                  ) && (
                    <Link href="/explore/venues">
                      <a
                        className={`relative mx-1 sm:w-200px ${
                          process.env.NEXT_PUBLIC_CATEGORY_ACTIVE === "Venues"
                            ? "w-[180px]"
                            : "w-85px"
                        } cursor-pointer`}>
                        <div
                          className={`flex flex-col items-center ${getClass(
                            "bg-gradient-black"
                          )} sm:h-120px h-79px sm:pt-6 pt-4 sm:px-0 px-0 rounded-t-lg`}>
                          <div
                            className={`md:text-xl text-xs font-bold ${getClass(
                              "text-white",
                              true
                            )} uppercase text-center sm:mb-15px mb-3`}>
                            {t("key_web_venues")}
                          </div>
                          <div className="md:block hidden">
                            <Image
                              width={28}
                              height={28}
                              src="/icons/ic_search_venue_web.png"
                              alt="venue"
                            />
                          </div>
                          <div className="md:hidden block">
                            <Image
                              width={20}
                              height={20}
                              src="/icons/ic_search_venue_web.png"
                              alt="venue"
                            />
                          </div>
                        </div>
                        <div className="bg-black4 flex justify-center items-center sm:h-55px h-auto py-2.5 rounded-b-lg">
                          <div className="md:text-xl text-xs font-bold text-white text-center">
                            {venueCount}
                          </div>
                        </div>
                      </a>
                    </Link>
                  )}
                  {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
                    (i) => i === "Rooftops"
                  ) && (
                    <Link href="/explore/rooftops">
                      <a
                        className={`relative mx-1 sm:w-200px ${
                          process.env.NEXT_PUBLIC_CATEGORY_ACTIVE === "Rooftops"
                            ? "w-[180px]"
                            : "w-85px"
                        } cursor-pointer`}>
                        <div
                          className={`flex flex-col items-center ${getClass(
                            "bg-gradient-black"
                          )} sm:h-120px h-79px sm:pt-6 pt-4 sm:px-0 px-0 rounded-t-lg`}>
                          <div
                            className={`md:text-xl text-xs font-bold ${getClass(
                              "text-white",
                              true
                            )} uppercase text-center sm:mb-15px mb-3`}>
                            {t("key_web_rooftops")}
                          </div>
                          <div className="md:block hidden">
                            <Image
                              width={36}
                              height={36}
                              src="/icons/rooftopclub/ic_search_general_web.png"
                              alt="rooftop"
                            />
                          </div>
                          <div className="md:hidden block">
                            <Image
                              width={28}
                              height={28}
                              src="/icons/rooftopclub/ic_search_general_web.png"
                              alt="rooftop"
                            />
                          </div>
                        </div>
                        <div
                          className={`${getClass(
                            "bg-black4",
                            true
                          )} flex justify-center items-center sm:h-55px h-auto py-2.5 rounded-b-lg`}>
                          <div
                            className={`md:text-xl text-xs font-bold ${getClass(
                              "text-white",
                              true
                            )} text-center`}>
                            {rooftopCount}
                          </div>
                        </div>
                      </a>
                    </Link>
                  )}
                  {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
                    (i) => i === "Spas"
                  ) && (
                    <Link href="/explore/spas">
                      <a
                        className={`relative mx-1 sm:w-200px ${
                          process.env.NEXT_PUBLIC_CATEGORY_ACTIVE === "Spas"
                            ? "w-[180px]"
                            : "w-85px"
                        } cursor-pointer`}>
                        <div className="flex flex-col items-center bg-gradient-to-b from-gradient4 to-gradient5 sm:h-120px h-79px sm:pt-6 pt-4 sm:px-12 px-0 rounded-t-lg">
                          <div className="md:text-xl text-xs font-bold text-white uppercase text-center sm:mb-15px mb-3">
                            {t("key_web_spas")}
                          </div>
                          <div className="md:block hidden">
                            <Image
                              width={36}
                              height={36}
                              src="/icons/spaclub/ic_search_general_web.png"
                              alt="spas"
                            />
                          </div>
                          <div className="md:hidden block">
                            <Image
                              width={28}
                              height={28}
                              src="/icons/spaclub/ic_search_general_web.png"
                              alt="spas"
                            />
                          </div>
                        </div>
                        <div
                          className={`${getClass(
                            "bg-black4",
                            true
                          )} flex justify-center items-center sm:h-55px h-auto py-2.5 rounded-b-lg`}>
                          <div
                            className={`md:text-xl text-xs font-bold ${getClass(
                              "text-white",
                              true
                            )} text-center`}>
                            {spaCount}
                          </div>
                        </div>
                      </a>
                    </Link>
                  )}
                  {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
                    (i) => i === "Venue Beachful"
                  ) && (
                    <Link href="/explore/beach-clubs">
                      <a
                        className={`relative mx-1 sm:w-200px ${
                          process.env.NEXT_PUBLIC_CATEGORY_ACTIVE ===
                          "Venue Beachful"
                            ? "w-[180px]"
                            : "w-85px"
                        } cursor-pointer`}>
                        <div
                          className={`flex flex-col items-center ${getClass(
                            "bg-gradient-black"
                          )} sm:h-120px h-79px sm:pt-6 pt-4 sm:px-0 px-0 rounded-t-lg`}>
                          <div
                            className={`md:text-xl text-xs font-bold ${getClass(
                              "text-white",
                              true
                            )} uppercase text-center sm:mb-15px mb-3`}>
                            {t("key_web_beachclubs")}
                          </div>
                          <div className="md:block hidden">
                            <Image
                              width={36}
                              height={36}
                              src="/icons/beachful/ic_search_general_web.png"
                              alt="beach clubs"
                            />
                          </div>
                          <div className="md:hidden block">
                            <Image
                              width={28}
                              height={28}
                              src="/icons/beachful/ic_search_general_web.png"
                              alt="beach clubs"
                            />
                          </div>
                        </div>
                        <div
                          className={`${getClass(
                            "bg-black4",
                            true
                          )} flex justify-center items-center sm:h-55px h-auto py-2.5 rounded-b-lg`}>
                          <div
                            className={`md:text-xl text-xs font-bold ${getClass(
                              "text-white",
                              true
                            )} text-center`}>
                            {venueBeachfulCount}
                          </div>
                        </div>
                      </a>
                    </Link>
                  )}
                  {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
                    (i) => i === "Prefab Home"
                  ) && (
                    <Link href="/explore/homes">
                      <a
                        className={`relative mx-1 sm:w-200px ${
                          process.env.NEXT_PUBLIC_CATEGORY_ACTIVE ===
                          "Prefab Home"
                            ? "w-[180px]"
                            : "w-85px"
                        } cursor-pointer`}>
                        <div
                          className={`flex flex-col items-center ${getClass(
                            "bg-gradient-black"
                          )} sm:h-120px h-79px sm:pt-6 pt-4 sm:px-0 px-0 rounded-t-lg`}>
                          <div
                            className={`md:text-xl text-xs font-bold ${getClass(
                              "text-white",
                              true
                            )} uppercase text-center sm:mb-15px mb-3`}>
                            {t("key_web_prefab_home")}
                          </div>
                          <div className="md:block hidden">
                            <Image
                              width={36}
                              height={36}
                              src="/icons/prefabworld/ic_search_general_web.png"
                              alt="homes"
                            />
                          </div>
                          <div className="md:hidden block">
                            <Image
                              width={28}
                              height={28}
                              src="/icons/prefabworld/ic_search_general_web.png"
                              alt="homes"
                            />
                          </div>
                        </div>
                        <div
                          className={`${getClass(
                            "bg-black4",
                            true
                          )} flex justify-center items-center sm:h-55px h-auto py-2.5 rounded-b-lg`}>
                          <div
                            className={`md:text-xl text-xs font-bold ${getClass(
                              "text-white",
                              true
                            )} text-center`}>
                            {prefabHomeCount}
                          </div>
                        </div>
                      </a>
                    </Link>
                  )}
                  {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
                    (i) => i === "Prefab Company"
                  ) && (
                    <Link href="/explore/companies">
                      <a
                        className={`relative mx-1 sm:w-200px ${
                          process.env.NEXT_PUBLIC_CATEGORY_ACTIVE ===
                          "Prefab Company"
                            ? "w-[180px]"
                            : "w-85px"
                        } cursor-pointer`}>
                        <div
                          className={`flex flex-col items-center ${getClass(
                            "bg-gradient-black"
                          )} sm:h-120px h-79px sm:pt-6 pt-4 sm:px-0 px-0 rounded-t-lg`}>
                          <div
                            className={`md:text-xl text-xs font-bold ${getClass(
                              "text-white",
                              true
                            )} uppercase text-center sm:mb-15px mb-3`}>
                            {t("key_web_prefab_company")}
                          </div>
                          <div className="md:block hidden">
                            <Image
                              width={36}
                              height={36}
                              src="/icons/prefabworld/ic_search_general_web.png"
                              alt="companies"
                            />
                          </div>
                          <div className="md:hidden block">
                            <Image
                              width={28}
                              height={28}
                              src="/icons/prefabworld/ic_search_general_web.png"
                              alt="companies"
                            />
                          </div>
                        </div>
                        <div
                          className={`${getClass(
                            "bg-black4",
                            true
                          )} flex justify-center items-center sm:h-55px h-auto py-2.5 rounded-b-lg`}>
                          <div
                            className={`md:text-xl text-xs font-bold ${getClass(
                              "text-white",
                              true
                            )} text-center`}>
                            {prefabCompanyCount}
                          </div>
                        </div>
                      </a>
                    </Link>
                  )}
                  {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
                    (i) => i === "Zoos"
                  ) && (
                    <Link href="/explore/zoos">
                      <a
                        className={`relative mx-1 sm:w-200px ${
                          process.env.NEXT_PUBLIC_CATEGORY_ACTIVE === "Zoos"
                            ? "w-[180px]"
                            : "w-85px"
                        } cursor-pointer`}>
                        <div
                          className={`flex flex-col items-center ${getClass(
                            "bg-gradient-black"
                          )} sm:h-120px h-79px sm:pt-6 pt-4 sm:px-0 px-0 rounded-t-lg`}>
                          <div
                            className={`md:text-xl text-xs font-bold ${getClass(
                              "text-white",
                              true
                            )} uppercase text-center sm:mb-15px mb-3`}>
                            Zoos
                          </div>
                          <div className="md:block hidden">
                            <Image
                              width={36}
                              height={36}
                              src="/icons/zoopedia/ic_search_general_web.png"
                              alt="zoos"
                            />
                          </div>
                          <div className="md:hidden block">
                            <Image
                              width={28}
                              height={28}
                              src="/icons/zoopedia/ic_search_general_web.png"
                              alt="zoos"
                            />
                          </div>
                        </div>
                        <div
                          className={`${getClass(
                            "bg-black4",
                            true
                          )} flex justify-center items-center sm:h-55px h-auto py-2.5 rounded-b-lg`}>
                          <div
                            className={`md:text-xl text-xs font-bold ${getClass(
                              "text-white",
                              true
                            )} text-center`}>
                            {zooCount}
                          </div>
                        </div>
                      </a>
                    </Link>
                  )}
                  {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
                    (i) => i === "Animals"
                  ) && (
                    <Link href="/explore/animals">
                      <a
                        className={`relative mx-1 sm:w-200px ${
                          process.env.NEXT_PUBLIC_CATEGORY_ACTIVE === "Animals"
                            ? "w-[180px]"
                            : "w-85px"
                        } cursor-pointer`}>
                        <div
                          className={`flex flex-col items-center ${getClass(
                            "bg-gradient-black"
                          )} sm:h-120px h-79px sm:pt-6 pt-4 sm:px-0 px-0 rounded-t-lg`}>
                          <div
                            className={`md:text-xl text-xs font-bold ${getClass(
                              "text-white",
                              true
                            )} uppercase text-center sm:mb-15px mb-3`}>
                            Animals
                          </div>
                          <div className="md:block hidden">
                            <Image
                              width={36}
                              height={36}
                              src="/icons/zoopedia/ic_search_general_web.png"
                              alt="animals"
                            />
                          </div>
                          <div className="md:hidden block">
                            <Image
                              width={28}
                              height={28}
                              src="/icons/zoopedia/ic_search_general_web.png"
                              alt="animals"
                            />
                          </div>
                        </div>
                        <div
                          className={`${getClass(
                            "bg-black4",
                            true
                          )} flex justify-center items-center sm:h-55px h-auto py-2.5 rounded-b-lg`}>
                          <div
                            className={`md:text-xl text-xs font-bold ${getClass(
                              "text-white",
                              true
                            )} text-center`}>
                            {animalCount}
                          </div>
                        </div>
                      </a>
                    </Link>
                  )}
                  {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
                    (i) => i === "Parks"
                  ) && (
                    <Link href="/explore/parks">
                      <a
                        className={`relative mx-1 sm:w-200px ${
                          process.env.NEXT_PUBLIC_CATEGORY_ACTIVE === "Parks"
                            ? "w-[180px]"
                            : "w-85px"
                        } cursor-pointer`}>
                        <div
                          className={`flex flex-col items-center ${getClass(
                            "bg-gradient-black"
                          )} sm:h-120px h-79px sm:pt-6 pt-4 sm:px-0 px-0 rounded-t-lg`}>
                          <div
                            className={`md:text-xl text-xs font-bold ${getClass(
                              "text-white",
                              true
                            )} uppercase text-center sm:mb-15px mb-3`}>
                            PARKS
                          </div>
                          <div className="md:block hidden">
                            <Image
                              width={36}
                              height={36}
                              src="/icons/parkful/ic_search_general_web.png"
                              alt="parks"
                            />
                          </div>
                          <div className="md:hidden block">
                            <Image
                              width={28}
                              height={28}
                              src="/icons/parkful/ic_search_general_web.png"
                              alt="parks"
                            />
                          </div>
                        </div>
                        <div
                          className={`${getClass(
                            "bg-black4",
                            true
                          )} flex justify-center items-center sm:h-55px h-auto py-2.5 rounded-b-lg`}>
                          <div
                            className={`md:text-xl text-xs font-bold ${getClass(
                              "text-white",
                              true
                            )} text-center`}>
                            {parkfulCount}
                          </div>
                        </div>
                      </a>
                    </Link>
                  )}
                  {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
                    (i) => i === "Casinos"
                  ) && (
                    <Link href="/explore/casinos">
                      <a
                        className={`relative mx-1 sm:w-200px ${
                          process.env.NEXT_PUBLIC_CATEGORY_ACTIVE === "Casinos"
                            ? "w-[180px]"
                            : "w-85px"
                        } cursor-pointer`}>
                        <div
                          className={`flex flex-col items-center ${getClass(
                            "bg-gradient-black"
                          )} sm:h-120px h-79px sm:pt-6 pt-4 sm:px-0 px-0 rounded-t-lg`}>
                          <div
                            className={`md:text-xl text-xs font-bold ${getClass(
                              "text-white",
                              true
                            )} uppercase text-center sm:mb-15px mb-3`}>
                            CASINOS
                          </div>
                          <div className="md:block hidden">
                            <Image
                              width={36}
                              height={36}
                              src="/icons/worldcasino/ic_search_general_web.png"
                              alt="casinos"
                            />
                          </div>
                          <div className="md:hidden block">
                            <Image
                              width={28}
                              height={28}
                              src="/icons/worldcasino/ic_search_general_web.png"
                              alt="casinos"
                            />
                          </div>
                        </div>
                        <div
                          className={`${getClass(
                            "bg-black4",
                            true
                          )} flex justify-center items-center sm:h-55px h-auto py-2.5 rounded-b-lg`}>
                          <div
                            className={`md:text-xl text-xs font-bold ${getClass(
                              "text-white",
                              true
                            )} text-center`}>
                            {casinoCount}
                          </div>
                        </div>
                      </a>
                    </Link>
                  )}
                  {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
                    (i) => i === "Castles"
                  ) && (
                    <Link href="/explore/castles">
                      <a
                        className={`relative mx-1 sm:w-200px ${
                          process.env.NEXT_PUBLIC_CATEGORY_ACTIVE === "Castles"
                            ? "w-[180px]"
                            : "w-85px"
                        } cursor-pointer`}>
                        <div
                          className={`flex flex-col items-center ${getClass(
                            "bg-gradient-black"
                          )} sm:h-120px h-79px sm:pt-6 pt-4 sm:px-0 px-0 rounded-t-lg`}>
                          <div
                            className={`md:text-xl text-xs font-bold ${getClass(
                              "text-white",
                              true
                            )} uppercase text-center sm:mb-15px mb-3`}>
                            CASTLES
                          </div>
                          <div className="md:block hidden">
                            <Image
                              width={36}
                              height={36}
                              src="/icons/castlepedia/ic_search_general_web.png"
                              alt="castles"
                            />
                          </div>
                          <div className="md:hidden block">
                            <Image
                              width={28}
                              height={28}
                              src="/icons/castlepedia/ic_search_general_web.png"
                              alt="castles"
                            />
                          </div>
                        </div>
                        <div
                          className={`${getClass(
                            "bg-black4",
                            true
                          )} flex justify-center items-center sm:h-55px h-auto py-2.5 rounded-b-lg`}>
                          <div
                            className={`md:text-xl text-xs font-bold ${getClass(
                              "text-white",
                              true
                            )} text-center`}>
                            {castleCount}
                          </div>
                        </div>
                      </a>
                    </Link>
                  )}
                  {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
                    (i) => i === "Resorts"
                  ) && (
                    <Link href="/explore/resorts">
                      <a
                        className={`relative mx-1 sm:w-200px ${
                          process.env.NEXT_PUBLIC_CATEGORY_ACTIVE === "Resorts"
                            ? "w-[180px]"
                            : "w-85px"
                        } cursor-pointer`}>
                        <div
                          className={`flex flex-col items-center ${getClass(
                            "bg-gradient-black"
                          )} sm:h-120px h-79px sm:pt-6 pt-4 sm:px-0 px-0 rounded-t-lg`}>
                          <div
                            className={`md:text-xl text-xs font-bold ${getClass(
                              "text-white",
                              true
                            )} uppercase text-center sm:mb-15px mb-3`}>
                            RESORTS
                          </div>
                          <div className="md:block hidden">
                            <Image
                              width={36}
                              height={36}
                              src="/icons/luxresortclub/ic_search_general_web.png"
                              alt="resorts"
                            />
                          </div>
                          <div className="md:hidden block">
                            <Image
                              width={28}
                              height={28}
                              src="/icons/luxresortclub/ic_search_general_web.png"
                              alt="resorts"
                            />
                          </div>
                        </div>
                        <div
                          className={`${getClass(
                            "bg-black4",
                            true
                          )} flex justify-center items-center sm:h-55px h-auto py-2.5 rounded-b-lg`}>
                          <div
                            className={`md:text-xl text-xs font-bold ${getClass(
                              "text-white",
                              true
                            )} text-center`}>
                            {resortCount}
                          </div>
                        </div>
                      </a>
                    </Link>
                  )}
                  {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
                    (i) => i === "Aparthotels"
                  ) && (
                    <Link href="/explore/aparthotels">
                      <a
                        className={`relative mx-1 sm:w-200px ${
                          process.env.NEXT_PUBLIC_CATEGORY_ACTIVE ===
                          "Aparthotels"
                            ? "w-[180px]"
                            : "w-85px"
                        } cursor-pointer`}>
                        <div
                          className={`flex flex-col items-center ${getClass(
                            "bg-gradient-black"
                          )} sm:h-120px h-79px sm:pt-6 pt-4 sm:px-0 px-0 rounded-t-lg`}>
                          <div
                            className={`md:text-xl text-xs font-bold ${getClass(
                              "text-white",
                              true
                            )} uppercase text-center sm:mb-15px mb-3`}>
                            APARTHOTELS
                          </div>
                          <div className="md:block hidden">
                            <Image
                              width={36}
                              height={36}
                              src="/icons/aparthotelclub/ic_search_general_web.png"
                              alt="aparthotels"
                            />
                          </div>
                          <div className="md:hidden block">
                            <Image
                              width={28}
                              height={28}
                              src="/icons/aparthotelclub/ic_search_general_web.png"
                              alt="aparthotels"
                            />
                          </div>
                        </div>
                        <div
                          className={`${getClass(
                            "bg-black4",
                            true
                          )} flex justify-center items-center sm:h-55px h-auto py-2.5 rounded-b-lg`}>
                          <div
                            className={`md:text-xl text-xs font-bold ${getClass(
                              "text-white",
                              true
                            )} text-center`}>
                            {aparthotelCount}
                          </div>
                        </div>
                      </a>
                    </Link>
                  )}
                  {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
                    (i) => i === "Venue Cocktail"
                  ) && (
                    <Link href="/explore/bars">
                      <a
                        className={`relative mx-1 sm:w-200px ${
                          process.env.NEXT_PUBLIC_CATEGORY_ACTIVE ===
                          "Venue Cocktail"
                            ? "w-[180px]"
                            : "w-85px"
                        } cursor-pointer`}>
                        <div className="flex flex-col items-center bg-gradient-to-b from-gradient4 to-gradient5 sm:h-120px h-79px sm:pt-6 pt-4 sm:px-12 px-0 rounded-t-lg">
                          <div className="md:text-xl text-xs font-bold text-white uppercase text-center sm:mb-15px mb-3">
                            {t("key_web_bars")}
                          </div>
                          <div className="md:block hidden">
                            <Image
                              width={36}
                              height={36}
                              src="/icons/cocktayl/ic_search_general_web.png"
                              alt="bars"
                            />
                          </div>
                          <div className="md:hidden block">
                            <Image
                              width={28}
                              height={28}
                              src="/icons/cocktayl/ic_search_general_web.png"
                              alt="bars"
                            />
                          </div>
                        </div>
                        <div className="bg-black4 flex justify-center items-center sm:h-55px h-auto py-2.5 rounded-b-lg">
                          <div className="md:text-xl text-xs font-bold text-white text-center">
                            {venueCocktailCount}
                          </div>
                        </div>
                      </a>
                    </Link>
                  )}
                  {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
                    (i) => i === "Festivals"
                  ) && (
                    <Link href="/explore/festivals">
                      <a className="relative mx-1 sm:w-200px w-85px cursor-pointer">
                        <div className="flex flex-col items-center bg-gradient-to-b from-gradient4 to-gradient5 sm:h-120px h-79px sm:pt-6 pt-4 sm:px-12 px-0 rounded-t-lg">
                          <div className="md:text-xl text-xs font-bold text-white uppercase text-center sm:mb-14px mb-3">
                            {t("key_web_festivals")}
                          </div>
                          <div className="md:block hidden">
                            <Image
                              width={28}
                              height={28}
                              src="/icons/ic_search_festival_web.png"
                              alt="festival"
                            />
                          </div>
                          <div className="md:hidden block">
                            <Image
                              width={20}
                              height={20}
                              src="/icons/ic_search_festival_web.png"
                              alt="festival"
                            />
                          </div>
                        </div>
                        <div className="bg-black4 flex justify-center items-center sm:h-55px h-auto py-2.5 rounded-b-lg">
                          <div className="md:text-xl text-xs font-bold text-white text-center">
                            {festivalCount}
                          </div>
                        </div>
                      </a>
                    </Link>
                  )}
                  {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
                    (i) => i === "Events"
                  ) && (
                    <Link href="/explore/events">
                      <a className="relative mx-1 sm:w-200px w-85px cursor-pointer">
                        <div className="flex flex-col items-center bg-gradient-to-b from-gradient4 to-gradient5 sm:h-120px h-79px sm:pt-6 pt-4 sm:px-12 px-0 rounded-t-lg">
                          <div className="md:text-xl text-xs font-bold text-white uppercase text-center sm:mb-14px mb-3">
                            {t("key_web_events")}
                          </div>
                          <div className="md:block hidden">
                            <Image
                              width={28}
                              height={28}
                              src="/icons/ic_search_event_web.png"
                              alt="event"
                            />
                          </div>
                          <div className="md:hidden block">
                            <Image
                              width={20}
                              height={20}
                              src="/icons/ic_search_event_web.png"
                              alt="event"
                            />
                          </div>
                        </div>
                        <div className="bg-black4 flex justify-center items-center sm:h-55px h-auto py-2.5 rounded-b-lg">
                          <div className="md:text-xl text-xs font-bold text-white text-center">
                            {eventCount}
                          </div>
                        </div>
                      </a>
                    </Link>
                  )}
                  {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
                    (i) => i === "Artists"
                  ) && (
                    <Link href="/explore/artists">
                      <a className="relative mx-1 sm:w-200px w-85px cursor-pointer">
                        <div className="flex flex-col items-center bg-gradient-to-b from-gradient4 to-gradient5 sm:h-120px h-79px sm:pt-6 pt-4 sm:px-12 px-0 rounded-t-lg">
                          <div className="md:text-xl text-xs font-bold text-white uppercase text-center sm:mb-15px mb-3">
                            {t("key_web_artists")}
                          </div>
                          <div className="md:block hidden">
                            <Image
                              width={21}
                              height={28}
                              src="/icons/ic_search_artist_web.png"
                              alt="artist"
                            />
                          </div>
                          <div className="md:hidden block">
                            <Image
                              width={15}
                              height={20}
                              src="/icons/ic_search_artist_web.png"
                              alt="artist"
                            />
                          </div>
                        </div>
                        <div className="bg-black4 flex justify-center items-center sm:h-55px h-auto py-2.5 rounded-b-lg">
                          <div className="md:text-xl text-xs font-bold text-white text-center">
                            {artistCount}
                          </div>
                        </div>
                      </a>
                    </Link>
                  )}
                </div>
              </div>
            </div>
            {process.env.NEXT_PUBLIC_APP_NAME !== "Festyful" &&
              process.env.NEXT_PUBLIC_APP_NAME !== "Vybeful" &&
              process.env.NEXT_PUBLIC_APP_NAME !== "RooftopClub" &&
              process.env.NEXT_PUBLIC_APP_NAME !== "Castlepedia" &&
              process.env.NEXT_PUBLIC_APP_NAME !== "Parkful" &&
              process.env.NEXT_PUBLIC_APP_NAME !== "Zoopedia" &&
              process.env.NEXT_PUBLIC_APP_NAME !== "AparthotelClub" &&
              process.env.NEXT_PUBLIC_APP_NAME !== "LuxResort Club" &&
              process.env.NEXT_PUBLIC_APP_NAME !== "SpaClub" &&
              process.env.NEXT_PUBLIC_APP_NAME !== "Beachful" &&
              process.env.NEXT_PUBLIC_APP_NAME !== "PrefabWorld" &&
              process.env.NEXT_PUBLIC_APP_NAME !== "WorldCasino" && (
                <div className="flex-1">&nbsp;</div>
              )}
            {process.env.NEXT_PUBLIC_APP_NAME === "Festyful" && (
              <div className="flex flex-1 items-end justify-center pb-[14px]">
                <div className="flex flex-row items-center">
                  <div className="px-2" style={{ height: 18 }}>
                    <a href="https://www.facebook.com/festyfulcom">
                      <Image
                        src="/icons/ic_facebook_circle.svg"
                        width={18}
                        height={18}
                        alt="facebook"
                      />
                    </a>
                  </div>
                  <div className="px-2" style={{ height: 18 }}>
                    <a href="https://www.instagram.com/festyfulcom">
                      <Image
                        src="/icons/ic_instagram_circle.svg"
                        width={19}
                        height={18}
                        alt="instagram"
                      />
                    </a>
                  </div>
                  <div className="px-2" style={{ height: 18 }}>
                    <a href="https://x.com/festyful_com">
                      <Image
                        src="/icons/ic_twitter_circle.svg"
                        width={18}
                        height={18}
                        alt="twitter"
                      />
                    </a>
                  </div>
                  <div className="px-2" style={{ height: 18 }}>
                    <a href="https://www.tiktok.com/@festyfulcom">
                      <Image
                        src="/icons/ic_tiktok_circle.svg"
                        width={18}
                        height={18}
                        alt="tiktok"
                      />
                    </a>
                  </div>
                  <div className="px-2" style={{ height: 18 }}>
                    <a href="https://www.youtube.com/@festyfulcom">
                      <Image
                        src="/icons/ic_youtube_circle.svg"
                        width={18}
                        height={18}
                        alt="youtube"
                      />
                    </a>
                  </div>
                </div>
              </div>
            )}
            {process.env.NEXT_PUBLIC_APP_NAME === "Vybeful" && (
              <div className="flex flex-1 items-end justify-center pb-[14px]">
                <div className="flex flex-row items-center">
                  <div className="px-2" style={{ height: 18 }}>
                    <a href="https://www.facebook.com/vybefulcom">
                      <Image
                        src="/icons/ic_facebook_circle.svg"
                        width={18}
                        height={18}
                        alt="facebook"
                      />
                    </a>
                  </div>
                  <div className="px-2" style={{ height: 18 }}>
                    <a href="https://www.instagram.com/vybefulcom">
                      <Image
                        src="/icons/ic_instagram_circle.svg"
                        width={19}
                        height={18}
                        alt="instagram"
                      />
                    </a>
                  </div>
                </div>
              </div>
            )}
            {process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" && (
              <div className="flex flex-1 items-end justify-center pb-[14px]">
                <div className="flex flex-row items-center">
                  <div className="px-2" style={{ height: 18 }}>
                    <a href="https://www.facebook.com/cocktayl.co">
                      <Image
                        src="/icons/ic_facebook_circle.svg"
                        width={18}
                        height={18}
                        alt="facebook"
                      />
                    </a>
                  </div>
                  <div className="px-2" style={{ height: 18 }}>
                    <a href="https://www.instagram.com/cocktayl.co">
                      <Image
                        src="/icons/ic_instagram_circle.svg"
                        width={19}
                        height={18}
                        alt="instagram"
                      />
                    </a>
                  </div>
                  <div className="px-2" style={{ height: 18 }}>
                    <a href="https://www.tiktok.com/@cocktayl.co">
                      <Image
                        src="/icons/ic_tiktok_circle.svg"
                        width={19}
                        height={18}
                        alt="tiktok"
                      />
                    </a>
                  </div>
                  <div className="px-2" style={{ height: 18 }}>
                    <a href="https://www.youtube.com/@cocktaylco">
                      <Image
                        src="/icons/ic_youtube_circle.svg"
                        width={19}
                        height={18}
                        alt="youtube"
                      />
                    </a>
                  </div>
                </div>
              </div>
            )}
            {process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" && (
              <div className="flex flex-1 items-end justify-center pb-[14px]">
                <div className="flex flex-row items-center">
                  <div className="px-2" style={{ height: 18 }}>
                    <a href="https://www.facebook.com/rooftopclub.co">
                      <Image
                        src="/icons/ic_facebook_circle.svg"
                        width={18}
                        height={18}
                        alt="facebook"
                      />
                    </a>
                  </div>
                  <div className="px-2" style={{ height: 18 }}>
                    <a href="https://www.instagram.com/rooftopclub.co">
                      <Image
                        src="/icons/ic_instagram_circle.svg"
                        width={19}
                        height={18}
                        alt="instagram"
                      />
                    </a>
                  </div>
                  <div className="px-2" style={{ height: 18 }}>
                    <a href="https://www.tiktok.com/@rooftopclub.co">
                      <Image
                        src="/icons/ic_tiktok_circle.svg"
                        width={19}
                        height={18}
                        alt="tiktok"
                      />
                    </a>
                  </div>
                  <div className="px-2" style={{ height: 18 }}>
                    <a href="https://www.youtube.com/@rooftopclubco">
                      <Image
                        src="/icons/ic_youtube_circle.svg"
                        width={19}
                        height={18}
                        alt="youtube"
                      />
                    </a>
                  </div>
                </div>
              </div>
            )}
            {process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" && (
              <div className="flex flex-1 items-end justify-center pb-[14px]">
                <div className="flex flex-row items-center">
                  <div className="px-2" style={{ height: 18 }}>
                    <a href="https://www.facebook.com/castlepedia.org">
                      <Image
                        src="/icons/ic_facebook_circle.svg"
                        width={18}
                        height={18}
                        alt="facebook"
                      />
                    </a>
                  </div>
                  <div className="px-2" style={{ height: 18 }}>
                    <a href="https://www.instagram.com/castlepedia">
                      <Image
                        src="/icons/ic_instagram_circle.svg"
                        width={19}
                        height={18}
                        alt="instagram"
                      />
                    </a>
                  </div>
                </div>
              </div>
            )}
            {process.env.NEXT_PUBLIC_APP_NAME === "Parkful" && (
              <div className="flex flex-1 items-end justify-center pb-[14px]">
                <div className="flex flex-row items-center">
                  <div className="px-2" style={{ height: 18 }}>
                    <a href="https://www.facebook.com/parkful.co">
                      <Image
                        src="/icons/ic_facebook_circle.svg"
                        width={18}
                        height={18}
                        alt="facebook"
                      />
                    </a>
                  </div>
                  <div className="px-2" style={{ height: 18 }}>
                    <a href="https://www.instagram.com/parkful_co">
                      <Image
                        src="/icons/ic_instagram_circle.svg"
                        width={19}
                        height={18}
                        alt="instagram"
                      />
                    </a>
                  </div>
                </div>
              </div>
            )}
            {process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" && (
              <div className="flex flex-1 items-end justify-center pb-[14px]">
                <div className="flex flex-row items-center">
                  <div className="px-2" style={{ height: 18 }}>
                    <a href="https://www.facebook.com/zoopedia.org">
                      <Image
                        src="/icons/ic_facebook_circle.svg"
                        width={18}
                        height={18}
                        alt="facebook"
                      />
                    </a>
                  </div>
                  <div className="px-2" style={{ height: 18 }}>
                    <a href="https://www.instagram.com/zoopediaorg">
                      <Image
                        src="/icons/ic_instagram_circle.svg"
                        width={19}
                        height={18}
                        alt="instagram"
                      />
                    </a>
                  </div>
                </div>
              </div>
            )}
            {process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" && (
              <div className="flex flex-1 items-end justify-center pb-[14px]">
                <div className="flex flex-row items-center">
                  <div className="px-2" style={{ height: 18 }}>
                    <a href="https://www.facebook.com/AparthotelClub">
                      <Image
                        src="/icons/ic_facebook_circle.svg"
                        width={18}
                        height={18}
                        alt="facebook"
                      />
                    </a>
                  </div>
                  <div className="px-2" style={{ height: 18 }}>
                    <a href="https://www.instagram.com/apart_hotel_club_com">
                      <Image
                        src="/icons/ic_instagram_circle.svg"
                        width={19}
                        height={18}
                        alt="instagram"
                      />
                    </a>
                  </div>
                </div>
              </div>
            )}
            {process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" && (
              <div className="flex flex-1 items-end justify-center pb-[14px]">
                <div className="flex flex-row items-center">
                  <div className="px-2" style={{ height: 18 }}>
                    <a href="https://www.facebook.com/luxresortclubcom">
                      <Image
                        src="/icons/ic_facebook_circle.svg"
                        width={18}
                        height={18}
                        alt="facebook"
                      />
                    </a>
                  </div>
                  <div className="px-2" style={{ height: 18 }}>
                    <a href="https://www.instagram.com/luxresortclub">
                      <Image
                        src="/icons/ic_instagram_circle.svg"
                        width={19}
                        height={18}
                        alt="instagram"
                      />
                    </a>
                  </div>
                </div>
              </div>
            )}
            {process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" && (
              <div className="flex flex-1 items-end justify-center pb-[14px]">
                <div className="flex flex-row items-center">
                  <div className="px-2" style={{ height: 18 }}>
                    <a href="https://www.facebook.com/spaclub.co">
                      <Image
                        src="/icons/ic_facebook_circle.svg"
                        width={18}
                        height={18}
                        alt="facebook"
                      />
                    </a>
                  </div>
                  <div className="px-2" style={{ height: 18 }}>
                    <a href="https://www.instagram.com/spaclub.co">
                      <Image
                        src="/icons/ic_instagram_circle.svg"
                        width={19}
                        height={18}
                        alt="instagram"
                      />
                    </a>
                  </div>
                  <div className="px-2" style={{ height: 18 }}>
                    <a href="https://www.tiktok.com/@spaclub.co">
                      <Image
                        src="/icons/ic_tiktok_circle.svg"
                        width={19}
                        height={18}
                        alt="tiktok"
                      />
                    </a>
                  </div>
                  <div className="px-2" style={{ height: 18 }}>
                    <a href="https://www.youtube.com/@spaclubco">
                      <Image
                        src="/icons/ic_youtube_circle.svg"
                        width={19}
                        height={18}
                        alt="youtube"
                      />
                    </a>
                  </div>
                </div>
              </div>
            )}
            {process.env.NEXT_PUBLIC_APP_NAME === "Beachful" && (
              <div className="flex flex-1 items-end justify-center pb-[14px]">
                <div className="flex flex-row items-center">
                  <div className="px-2" style={{ height: 18 }}>
                    <a href="https://www.facebook.com/beachful.co">
                      <Image
                        src="/icons/ic_facebook_circle.svg"
                        width={18}
                        height={18}
                        alt="facebook"
                      />
                    </a>
                  </div>
                  <div className="px-2" style={{ height: 18 }}>
                    <a href="https://www.instagram.com/beachfulco">
                      <Image
                        src="/icons/ic_instagram_circle.svg"
                        width={19}
                        height={18}
                        alt="instagram"
                      />
                    </a>
                  </div>
                  <div className="px-2" style={{ height: 18 }}>
                    <a href="https://www.tiktok.com/@beachfulco">
                      <Image
                        src="/icons/ic_tiktok_circle.svg"
                        width={19}
                        height={18}
                        alt="tiktok"
                      />
                    </a>
                  </div>
                  <div className="px-2" style={{ height: 18 }}>
                    <a href="https://www.youtube.com/@beachfulco">
                      <Image
                        src="/icons/ic_youtube_circle.svg"
                        width={19}
                        height={18}
                        alt="youtube"
                      />
                    </a>
                  </div>
                </div>
              </div>
            )}
            {process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" && (
              <div className="flex flex-1 items-end justify-center pb-[14px]">
                <div className="flex flex-row items-center">
                  <div className="px-2" style={{ height: 18 }}>
                    <a href="https://www.facebook.com/prefabworld.co">
                      <Image
                        src="/icons/ic_facebook_circle.svg"
                        width={18}
                        height={18}
                        alt="facebook"
                      />
                    </a>
                  </div>
                  <div className="px-2" style={{ height: 18 }}>
                    <a href="https://www.instagram.com/prefabworldco">
                      <Image
                        src="/icons/ic_instagram_circle.svg"
                        width={19}
                        height={18}
                        alt="instagram"
                      />
                    </a>
                  </div>
                </div>
              </div>
            )}
            {process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" && (
              <div className="flex flex-1 items-end justify-center pb-[14px]">
                <div className="flex flex-row items-center">
                  <div className="px-2" style={{ height: 18 }}>
                    <a href="https://www.facebook.com/worldcasino.org">
                      <Image
                        src="/icons/ic_facebook_circle.svg"
                        width={18}
                        height={18}
                        alt="facebook"
                      />
                    </a>
                  </div>
                  <div className="px-2" style={{ height: 18 }}>
                    <a href="https://www.instagram.com/worldcasino.org1/">
                      <Image
                        src="/icons/ic_instagram_circle.svg"
                        width={19}
                        height={18}
                        alt="instagram"
                      />
                    </a>
                  </div>
                  <div className="px-2" style={{ height: 18 }}>
                    <a href="https://x.com/worldcasinoorg">
                      <Image
                        src="/icons/ic_twitter_circle.svg"
                        width={18}
                        height={18}
                        alt="twitter"
                      />
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Hero Section Desktop */}
      <div
        id="home"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "bottom center",
        }}
        className="sm:block hidden sm:h-auto h-full flex flex-1">
        <div
          className={`w-full h-full flex flex-1 ${getClass(
            "bg-black6",
            true
          )}`}>
          <div className="wrapper sm:block flex flex-col justify-center mx-auto relative md:h-800px md:py-0 sm:py-20 py-0 sm:pt-0 pt-20 sm:pb-0 pb-32">
            {/* <div className="flex sm:flex-col flex-row justify-center items-center absolute sm:pt-304px pt-0 sm:top-0 sm:bottom-auto bottom-10 sm:left-auto left-0 sm:right-auto right-0">
              <a
                className="inline-block sm:pb-30px pb-0 sm:mr-0 mr-60px"
                href="https://play.google.com/store/apps/details?id=com.soundclub"
                target="_blank"
                rel="noreferrer">
                <CImage
                  source="/icons/ic_googleplay.svg"
                  width={25}
                  height={25}
                  layout="fixed"
                  alt="googleplay"
                />
              </a>
              <a
                className="inline-block py-0"
                href="https://apps.apple.com/us/app/soundclub-discover-festivals/id1625045664"
                target="_blank"
                rel="noreferrer">
                <CImage
                  source="/icons/ic_appstore.svg"
                  width={25}
                  height={25}
                  layout="fixed"
                  alt="appstore"
                />
              </a>
            </div> */}
            <div className="flex flex-col items-center sm:h-full sm:pb-73px mb-0 md:pt-128px sm:pt-20 pt-0">
              <div
                data-wow-delay="1.5s"
                data-wow-iteration="1"
                className={`wow fadeOut h-45px flex flex-row flex-wrap items-center justify-center md:text-40px text-2xl font-bold text-white text-center sm:mb-146px mb-0 ${
                  showTagline ? "" : "opacity-0"
                }`}>
                <div className="wow fadeInDown mr-3">
                  {process.env.NEXT_PUBLIC_APP_SLOGAN}
                </div>
              </div>
              <form
                action={`/explore/${process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(
                  ","
                )[0]
                  ?.toLowerCase()
                  ?.replace("venue cocktail", "bars")
                  ?.replace("venue beachful", "beach-clubs")
                  ?.replace("prefab company", "companies")
                  ?.replace("prefab home", "homes")}`}
                className={`${getClass(
                  "bg-grayBackground3",
                  true
                )} rounded-full sm:flex hidden flex-row items-center justify-between h-50px md:w-670px w-full mb-98px`}>
                <div className="flex flex-row items-center">
                  <button
                    type="submit"
                    className={`flex flex-row justify-center items-center ${getClass(
                      "btn-gradient"
                    )} h-50px w-100px -mr-1 rounded-full cursor-pointer`}
                    onClick={() =>
                      router.push(
                        `/explore/${process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(
                          ","
                        )[0]
                          ?.toLowerCase()
                          ?.replace("venue cocktail", "bars")

                          ?.replace("venue beachful", "beach-clubs")
                          ?.replace("prefab company", "companies")
                          ?.replace("prefab home", "homes")}?search=${keyword}`
                      )
                    }>
                    <img src="/icons/ic_search.svg" alt="search" />
                  </button>
                  <input
                    name="search"
                    type="search"
                    aria-label={`Search ${process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(
                      ","
                    )
                      .join(" | ")
                      ?.replace("Festivals", "Festival")
                      ?.replace("Artists", "Artist")
                      ?.replace("Venues", "Venue")
                      ?.replace("Rooftops", "Rooftop")
                      ?.replace("Spas", "Spa")
                      ?.replace("Venue Cocktail", "Bar")
                      ?.replace("Venue Beachful", "Beach Club")
                      ?.replace("Prefab Company", "Company")
                      ?.replace("Prefab Home", "Home")
                      ?.replace("Parks", "Park")
                      ?.replace("Casinos", "Casino")
                      ?.replace("Castles", "Castle")
                      ?.replace("Resorts", "Resort")
                      ?.replace("Aparthotels", "Aparthotel")}`}
                    className={`bg-transparent font-regular md:text-lg text-sm ${getClass(
                      "text-white",
                      true
                    )} ${getClass(
                      "placeholder-input"
                    )} md:ml-8 ml-4 outline-0 w-400px`}
                    placeholder={process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(
                      ","
                    )
                      .join(" | ")
                      ?.replace("Festivals", "Festival")
                      ?.replace("Artists", "Artist")
                      ?.replace("Venues", "Venue")
                      ?.replace("Rooftops", "Rooftop")
                      ?.replace("Spas", "Spa")
                      ?.replace("Venue Cocktail", "Bar")
                      ?.replace("Venue Beachful", "Beach Club")
                      ?.replace("Prefab Company", "Company")
                      ?.replace("Prefab Home", "Home")
                      ?.replace("Parks", "Park")
                      ?.replace("Casinos", "Casino")
                      ?.replace("Castles", "Castle")
                      ?.replace("Resorts", "Resort")
                      ?.replace("Aparthotels", "Aparthotel")}
                    onChange={(e) => setKeyword(e.target.value)}
                    value={keyword}
                    onKeyDown={(e) =>
                      e.key === "Enter"
                        ? router.push(
                            `/explore/${process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(
                              ","
                            )[0]
                              ?.toLowerCase()
                              ?.replace("venue cocktail", "bars")

                              ?.replace("venue beachful", "beach-clubs")
                              ?.replace("prefab company", "companies")
                              ?.replace("prefab home", "homes")
                              ?.replace("parkful", "parks")}?search=${keyword}`
                          )
                        : null
                    }
                  />
                </div>
                <div
                  className={`flex flex-row justify-center items-center ${getClass(
                    "btn-gradient"
                  )} h-50px -mr-1 w-100px w-full rounded-full cursor-pointer`}
                  onClick={() =>
                    router.push(
                      `/explore/${process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(
                        ","
                      )[0]
                        ?.toLowerCase()
                        ?.replace("venue cocktail", "bars")
                        ?.replace("venue beachful", "beach-clubs")
                        ?.replace("prefab company", "companies")
                        ?.replace("prefab home", "homes")}`
                    )
                  }>
                  <img src="/icons/ic_filter.svg" alt="filter" />
                </div>
              </form>

              <Link
                href={`/explore/${process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(
                  ","
                )[0]
                  ?.toLowerCase()
                  ?.replace("venue cocktail", "bars")
                  ?.replace("venue beachful", "beach-clubs")
                  ?.replace("prefab company", "companies")
                  ?.replace("prefab home", "homes")}`}>
                <a
                  className={`sm:hidden block w-190px sm:mt-80px mt-0 mb-[20px] flex flex-row justify-center items-center ${getClass(
                    "btn-gradient"
                  )} py-1 px-6 rounded-full cursor-pointer uppercase font-bold text-white`}>
                  Explore
                </a>
              </Link>
              <Link href={`/guides`}>
                <a
                  className={`sm:hidden block w-190px sm:mt-80px mt-0 mb-60px flex flex-row justify-center items-center ${getClass(
                    "btn-gradient"
                  )} py-1 px-6 rounded-full cursor-pointer uppercase font-bold text-white`}>
                  Guides
                </a>
              </Link>
              <div className="relative">
                <div className="flex flex-row items-center mb-20px px-3">
                  <div
                    className={`${getClass(
                      "bg-gradient1",
                      true
                    )} h-2px rounded-full w-full`}
                  />
                  <div className="md:text-2xl text-lg font-bold text-white uppercase text-center mx-9 relative z-5">
                    {t("key_web_discover")}
                  </div>
                  <div
                    className={`${getClass(
                      "bg-gradient1",
                      true
                    )} h-2px rounded-full w-full`}
                  />
                </div>
                <div className="flex flex-row items-center">
                  {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
                    (i) => i === "Venues"
                  ) && (
                    <Link href="/explore/venues">
                      <a className="relative mx-1 sm:w-200px w-85px cursor-pointer sm:mb-0 mb-4">
                        <div
                          className={`flex flex-col items-center ${getClass(
                            "bg-gradient-black"
                          )} sm:h-120px h-79px sm:pt-6 pt-4 sm:px-0 px-0 rounded-t-lg`}>
                          <div
                            className={`md:text-xl text-xs font-bold ${getClass(
                              "text-white",
                              true
                            )} uppercase text-center sm:mb-15px mb-3`}>
                            {t("key_web_venues")}
                          </div>
                          <div className="md:block hidden">
                            <Image
                              width={28}
                              height={28}
                              src="/icons/ic_search_venue_web.png"
                              alt="venue"
                            />
                          </div>
                          <div className="md:hidden block">
                            <Image
                              width={20}
                              height={20}
                              src="/icons/ic_search_venue_web.png"
                              alt="venue"
                            />
                          </div>
                        </div>
                        <div
                          className={`${getClass(
                            "bg-black4",
                            true
                          )} flex justify-center items-center sm:h-55px h-auto py-2.5 rounded-b-lg`}>
                          <div
                            className={`md:text-xl text-xs font-bold ${getClass(
                              "text-white",
                              true
                            )} text-center`}>
                            {venueCount}
                          </div>
                        </div>
                      </a>
                    </Link>
                  )}
                  {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
                    (i) => i === "Rooftops"
                  ) && (
                    <Link href="/explore/rooftops">
                      <a className="relative mx-1 sm:w-200px w-85px cursor-pointer sm:mb-0 mb-4">
                        <div
                          className={`flex flex-col items-center ${getClass(
                            "bg-gradient-black"
                          )} sm:h-120px h-79px sm:pt-6 pt-4 sm:px-0 px-0 rounded-t-lg`}>
                          <div
                            className={`md:text-xl text-xs font-bold ${getClass(
                              "text-white",
                              true
                            )} uppercase text-center sm:mb-15px mb-3`}>
                            {t("key_web_rooftops")}
                          </div>
                          <div className="md:block hidden">
                            <Image
                              width={36}
                              height={36}
                              src="/icons/rooftopclub/ic_search_general_web.png"
                              alt="rooftop"
                            />
                          </div>
                          <div className="md:hidden block">
                            <Image
                              width={28}
                              height={28}
                              src="/icons/rooftopclub/ic_search_general_web.png"
                              alt="rooftop"
                            />
                          </div>
                        </div>
                        <div
                          className={`${getClass(
                            "bg-black4",
                            true
                          )} flex justify-center items-center sm:h-55px h-auto py-2.5 rounded-b-lg`}>
                          <div
                            className={`md:text-xl text-xs font-bold ${getClass(
                              "text-white",
                              true
                            )} text-center`}>
                            {rooftopCount}
                          </div>
                        </div>
                      </a>
                    </Link>
                  )}
                  {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
                    (i) => i === "Spas"
                  ) && (
                    <Link href="/explore/spas">
                      <a className="relative mx-1 sm:w-200px w-85px cursor-pointer sm:mb-0 mb-4">
                        <div
                          className={`flex flex-col items-center ${getClass(
                            "bg-gradient-black"
                          )} sm:h-120px h-79px sm:pt-6 pt-4 sm:px-0 px-0 rounded-t-lg`}>
                          <div
                            className={`md:text-xl text-xs font-bold ${getClass(
                              "text-white",
                              true
                            )} uppercase text-center sm:mb-15px mb-3`}>
                            {t("key_web_spas")}
                          </div>
                          <div className="md:block hidden">
                            <Image
                              width={36}
                              height={36}
                              src="/icons/spaclub/ic_search_general_web.png"
                              alt="spas"
                            />
                          </div>
                          <div className="md:hidden block">
                            <Image
                              width={28}
                              height={28}
                              src="/icons/spaclub/ic_search_general_web.png"
                              alt="spas"
                            />
                          </div>
                        </div>
                        <div
                          className={`${getClass(
                            "bg-black4",
                            true
                          )} flex justify-center items-center sm:h-55px h-auto py-2.5 rounded-b-lg`}>
                          <div
                            className={`md:text-xl text-xs font-bold ${getClass(
                              "text-white",
                              true
                            )} text-center`}>
                            {spaCount}
                          </div>
                        </div>
                      </a>
                    </Link>
                  )}
                  {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
                    (i) => i === "Venue Beachful"
                  ) && (
                    <Link href="/explore/beach-clubs">
                      <a className="relative mx-1 sm:w-200px w-85px cursor-pointer sm:mb-0 mb-4">
                        <div
                          className={`flex flex-col items-center ${getClass(
                            "bg-gradient-black"
                          )} sm:h-120px h-79px sm:pt-6 pt-4 sm:px-0 px-0 rounded-t-lg`}>
                          <div
                            className={`md:text-xl text-xs font-bold ${getClass(
                              "text-white",
                              true
                            )} uppercase text-center sm:mb-15px mb-3`}>
                            {t("key_web_beachclubs")}
                          </div>
                          <div className="md:block hidden">
                            <Image
                              width={36}
                              height={36}
                              src="/icons/beachful/ic_search_general_web.png"
                              alt="beach clubs"
                            />
                          </div>
                          <div className="md:hidden block">
                            <Image
                              width={28}
                              height={28}
                              src="/icons/beachful/ic_search_general_web.png"
                              alt="beach clubs"
                            />
                          </div>
                        </div>
                        <div
                          className={`${getClass(
                            "bg-black4",
                            true
                          )} flex justify-center items-center sm:h-55px h-auto py-2.5 rounded-b-lg`}>
                          <div
                            className={`md:text-xl text-xs font-bold ${getClass(
                              "text-white",
                              true
                            )} text-center`}>
                            {venueBeachfulCount}
                          </div>
                        </div>
                      </a>
                    </Link>
                  )}
                  {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
                    (i) => i === "Prefab Home"
                  ) && (
                    <Link href="/explore/homes">
                      <a className="relative mx-1 sm:w-200px w-85px cursor-pointer sm:mb-0 mb-4">
                        <div
                          className={`flex flex-col items-center ${getClass(
                            "bg-gradient-black"
                          )} sm:h-120px h-79px sm:pt-6 pt-4 sm:px-0 px-0 rounded-t-lg`}>
                          <div
                            className={`md:text-xl text-xs font-bold ${getClass(
                              "text-white",
                              true
                            )} uppercase text-center sm:mb-15px mb-3`}>
                            {t("key_web_prefab_home")}
                          </div>
                          <div className="md:block hidden">
                            <Image
                              width={36}
                              height={36}
                              src="/icons/prefabworld/ic_search_general_web.png"
                              alt="homes"
                            />
                          </div>
                          <div className="md:hidden block">
                            <Image
                              width={28}
                              height={28}
                              src="/icons/prefabworld/ic_search_general_web.png"
                              alt="homes"
                            />
                          </div>
                        </div>
                        <div
                          className={`${getClass(
                            "bg-black4",
                            true
                          )} flex justify-center items-center sm:h-55px h-auto py-2.5 rounded-b-lg`}>
                          <div
                            className={`md:text-xl text-xs font-bold ${getClass(
                              "text-white",
                              true
                            )} text-center`}>
                            {prefabHomeCount}
                          </div>
                        </div>
                      </a>
                    </Link>
                  )}
                  {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
                    (i) => i === "Prefab Company"
                  ) && (
                    <Link href="/explore/companies">
                      <a className="relative mx-1 sm:w-200px w-85px cursor-pointer sm:mb-0 mb-4">
                        <div
                          className={`flex flex-col items-center ${getClass(
                            "bg-gradient-black"
                          )} sm:h-120px h-79px sm:pt-6 pt-4 sm:px-0 px-0 rounded-t-lg`}>
                          <div
                            className={`md:text-xl text-xs font-bold ${getClass(
                              "text-white",
                              true
                            )} uppercase text-center sm:mb-15px mb-3`}>
                            {t("key_web_prefab_company")}
                          </div>
                          <div className="md:block hidden">
                            <Image
                              width={36}
                              height={36}
                              src="/icons/prefabworld/ic_search_general_web.png"
                              alt="companies"
                            />
                          </div>
                          <div className="md:hidden block">
                            <Image
                              width={28}
                              height={28}
                              src="/icons/prefabworld/ic_search_general_web.png"
                              alt="companies"
                            />
                          </div>
                        </div>
                        <div
                          className={`${getClass(
                            "bg-black4",
                            true
                          )} flex justify-center items-center sm:h-55px h-auto py-2.5 rounded-b-lg`}>
                          <div
                            className={`md:text-xl text-xs font-bold ${getClass(
                              "text-white",
                              true
                            )} text-center`}>
                            {prefabCompanyCount}
                          </div>
                        </div>
                      </a>
                    </Link>
                  )}
                  {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
                    (i) => i === "Zoos"
                  ) && (
                    <Link href="/explore/zoos">
                      <a className="relative mx-1 sm:w-200px w-85px cursor-pointer sm:mb-0 mb-4">
                        <div
                          className={`flex flex-col items-center ${getClass(
                            "bg-gradient-black"
                          )} sm:h-120px h-79px sm:pt-6 pt-4 sm:px-0 px-0 rounded-t-lg`}>
                          <div
                            className={`md:text-xl text-xs font-bold ${getClass(
                              "text-white",
                              true
                            )} uppercase text-center sm:mb-15px mb-3`}>
                            Zoos
                          </div>
                          <div className="md:block hidden">
                            <Image
                              width={36}
                              height={36}
                              src="/icons/zoopedia/ic_search_general_web.png"
                              alt="zoos"
                            />
                          </div>
                          <div className="md:hidden block">
                            <Image
                              width={28}
                              height={28}
                              src="/icons/zoopedia/ic_search_general_web.png"
                              alt="zoos"
                            />
                          </div>
                        </div>
                        <div
                          className={`${getClass(
                            "bg-black4",
                            true
                          )} flex justify-center items-center sm:h-55px h-auto py-2.5 rounded-b-lg`}>
                          <div
                            className={`md:text-xl text-xs font-bold ${getClass(
                              "text-white",
                              true
                            )} text-center`}>
                            {zooCount}
                          </div>
                        </div>
                      </a>
                    </Link>
                  )}
                  {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
                    (i) => i === "Animals"
                  ) && (
                    <Link href="/explore/animals">
                      <a className="relative mx-1 sm:w-200px w-85px cursor-pointer sm:mb-0 mb-4">
                        <div
                          className={`flex flex-col items-center ${getClass(
                            "bg-gradient-black"
                          )} sm:h-120px h-79px sm:pt-6 pt-4 sm:px-0 px-0 rounded-t-lg`}>
                          <div
                            className={`md:text-xl text-xs font-bold ${getClass(
                              "text-white",
                              true
                            )} uppercase text-center sm:mb-15px mb-3`}>
                            Animals
                          </div>
                          <div className="md:block hidden">
                            <Image
                              width={36}
                              height={36}
                              src="/icons/zoopedia/ic_search_general_web.png"
                              alt="animals"
                            />
                          </div>
                          <div className="md:hidden block">
                            <Image
                              width={28}
                              height={28}
                              src="/icons/zoopedia/ic_search_general_web.png"
                              alt="animals"
                            />
                          </div>
                        </div>
                        <div
                          className={`${getClass(
                            "bg-black4",
                            true
                          )} flex justify-center items-center sm:h-55px h-auto py-2.5 rounded-b-lg`}>
                          <div
                            className={`md:text-xl text-xs font-bold ${getClass(
                              "text-white",
                              true
                            )} text-center`}>
                            {animalCount}
                          </div>
                        </div>
                      </a>
                    </Link>
                  )}
                  {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
                    (i) => i === "Parks"
                  ) && (
                    <Link href="/explore/parks">
                      <a className="relative mx-1 sm:w-200px w-85px cursor-pointer sm:mb-0 mb-4">
                        <div
                          className={`flex flex-col items-center ${getClass(
                            "bg-gradient-black"
                          )} sm:h-120px h-79px sm:pt-6 pt-4 sm:px-0 px-0 rounded-t-lg`}>
                          <div
                            className={`md:text-xl text-xs font-bold ${getClass(
                              "text-white",
                              true
                            )} uppercase text-center sm:mb-15px mb-3`}>
                            PARKS
                          </div>
                          <div className="md:block hidden">
                            <Image
                              width={36}
                              height={36}
                              src="/icons/parkful/ic_search_general_web.png"
                              alt="parks"
                            />
                          </div>
                          <div className="md:hidden block">
                            <Image
                              width={28}
                              height={28}
                              src="/icons/parkful/ic_search_general_web.png"
                              alt="parks"
                            />
                          </div>
                        </div>
                        <div
                          className={`${getClass(
                            "bg-black4",
                            true
                          )} flex justify-center items-center sm:h-55px h-auto py-2.5 rounded-b-lg`}>
                          <div
                            className={`md:text-xl text-xs font-bold ${getClass(
                              "text-white",
                              true
                            )} text-center`}>
                            {parkfulCount}
                          </div>
                        </div>
                      </a>
                    </Link>
                  )}

                  {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
                    (i) => i === "Casinos"
                  ) && (
                    <Link href="/explore/casinos">
                      <a className="relative mx-1 sm:w-200px w-85px cursor-pointer sm:mb-0 mb-4">
                        <div
                          className={`flex flex-col items-center ${getClass(
                            "bg-gradient-black"
                          )} sm:h-120px h-79px sm:pt-6 pt-4 sm:px-0 px-0 rounded-t-lg`}>
                          <div
                            className={`md:text-xl text-xs font-bold ${getClass(
                              "text-white",
                              true
                            )} uppercase text-center sm:mb-15px mb-3`}>
                            CASINOS
                          </div>
                          <div className="md:block hidden">
                            <Image
                              width={36}
                              height={36}
                              src="/icons/worldcasino/ic_search_general_web.png"
                              alt="casinos"
                            />
                          </div>
                          <div className="md:hidden block">
                            <Image
                              width={28}
                              height={28}
                              src="/icons/worldcasino/ic_search_general_web.png"
                              alt="casinos"
                            />
                          </div>
                        </div>
                        <div
                          className={`${getClass(
                            "bg-black4",
                            true
                          )} flex justify-center items-center sm:h-55px h-auto py-2.5 rounded-b-lg`}>
                          <div
                            className={`md:text-xl text-xs font-bold ${getClass(
                              "text-white",
                              true
                            )} text-center`}>
                            {casinoCount}
                          </div>
                        </div>
                      </a>
                    </Link>
                  )}
                  {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
                    (i) => i === "Castles"
                  ) && (
                    <Link href="/explore/castles">
                      <a className="relative mx-1 sm:w-200px w-85px cursor-pointer sm:mb-0 mb-4">
                        <div
                          className={`flex flex-col items-center ${getClass(
                            "bg-gradient-black"
                          )} sm:h-120px h-79px sm:pt-6 pt-4 sm:px-0 px-0 rounded-t-lg`}>
                          <div
                            className={`md:text-xl text-xs font-bold ${getClass(
                              "text-white",
                              true
                            )} uppercase text-center sm:mb-15px mb-3`}>
                            CASTLES
                          </div>
                          <div className="md:block hidden">
                            <Image
                              width={36}
                              height={36}
                              src="/icons/castlepedia/ic_search_general_web.png"
                              alt="castles"
                            />
                          </div>
                          <div className="md:hidden block">
                            <Image
                              width={28}
                              height={28}
                              src="/icons/castlepedia/ic_search_general_web.png"
                              alt="castles"
                            />
                          </div>
                        </div>
                        <div
                          className={`${getClass(
                            "bg-black4",
                            true
                          )} flex justify-center items-center sm:h-55px h-auto py-2.5 rounded-b-lg`}>
                          <div
                            className={`md:text-xl text-xs font-bold ${getClass(
                              "text-white",
                              true
                            )} text-center`}>
                            {castleCount}
                          </div>
                        </div>
                      </a>
                    </Link>
                  )}
                  {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
                    (i) => i === "Resorts"
                  ) && (
                    <Link href="/explore/resorts">
                      <a className="relative mx-1 sm:w-200px w-85px cursor-pointer sm:mb-0 mb-4">
                        <div
                          className={`flex flex-col items-center ${getClass(
                            "bg-gradient-black"
                          )} sm:h-120px h-79px sm:pt-6 pt-4 sm:px-0 px-0 rounded-t-lg`}>
                          <div
                            className={`md:text-xl text-xs font-bold ${getClass(
                              "text-white",
                              true
                            )} uppercase text-center sm:mb-15px mb-3`}>
                            RESORTS
                          </div>
                          <div className="md:block hidden">
                            <Image
                              width={36}
                              height={36}
                              src="/icons/luxresortclub/ic_search_general_web.png"
                              alt="resorts"
                            />
                          </div>
                          <div className="md:hidden block">
                            <Image
                              width={28}
                              height={28}
                              src="/icons/luxresortclub/ic_search_general_web.png"
                              alt="resorts"
                            />
                          </div>
                        </div>
                        <div
                          className={`${getClass(
                            "bg-black4",
                            true
                          )} flex justify-center items-center sm:h-55px h-auto py-2.5 rounded-b-lg`}>
                          <div
                            className={`md:text-xl text-xs font-bold ${getClass(
                              "text-white",
                              true
                            )} text-center`}>
                            {resortCount}
                          </div>
                        </div>
                      </a>
                    </Link>
                  )}
                  {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
                    (i) => i === "Aparthotels"
                  ) && (
                    <Link href="/explore/aparthotels">
                      <a className="relative mx-1 sm:w-200px w-85px cursor-pointer sm:mb-0 mb-4">
                        <div
                          className={`flex flex-col items-center ${getClass(
                            "bg-gradient-black"
                          )} sm:h-120px h-79px sm:pt-6 pt-4 sm:px-0 px-0 rounded-t-lg`}>
                          <div
                            className={`md:text-xl text-xs font-bold ${getClass(
                              "text-white",
                              true
                            )} uppercase text-center sm:mb-15px mb-3`}>
                            APARTHOTELS
                          </div>
                          <div className="md:block hidden">
                            <Image
                              width={36}
                              height={36}
                              src="/icons/aparthotelclub/ic_search_general_web.png"
                              alt="aparthotels"
                            />
                          </div>
                          <div className="md:hidden block">
                            <Image
                              width={28}
                              height={28}
                              src="/icons/aparthotelclub/ic_search_general_web.png"
                              alt="aparthotels"
                            />
                          </div>
                        </div>
                        <div
                          className={`${getClass(
                            "bg-black4",
                            true
                          )} flex justify-center items-center sm:h-55px h-auto py-2.5 rounded-b-lg`}>
                          <div
                            className={`md:text-xl text-xs font-bold ${getClass(
                              "text-white",
                              true
                            )} text-center`}>
                            {aparthotelCount}
                          </div>
                        </div>
                      </a>
                    </Link>
                  )}
                  {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
                    (i) => i === "Venue Cocktail"
                  ) && (
                    <Link href="/explore/bars">
                      <a className="relative mx-1 sm:w-200px w-85px cursor-pointer sm:mb-0 mb-4">
                        <div className="flex flex-col items-center bg-gradient-to-b from-gradient4 to-gradient5 sm:h-120px h-79px sm:pt-6 pt-4 sm:px-12 px-0 rounded-t-lg">
                          <div className="md:text-xl text-xs font-bold text-white uppercase text-center sm:mb-15px mb-3">
                            {t("key_web_bars")}
                          </div>
                          <div className="md:block hidden">
                            <Image
                              width={36}
                              height={36}
                              src="/icons/cocktayl/ic_search_general_web.png"
                              alt="bars"
                            />
                          </div>
                          <div className="md:hidden block">
                            <Image
                              width={28}
                              height={28}
                              src="/icons/cocktayl/ic_search_general_web.png"
                              alt="bars"
                            />
                          </div>
                        </div>
                        <div className="bg-black4 flex justify-center items-center sm:h-55px h-auto py-2.5 rounded-b-lg">
                          <div className="md:text-xl text-xs font-bold text-white text-center">
                            {venueCocktailCount}
                          </div>
                        </div>
                      </a>
                    </Link>
                  )}
                  {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
                    (i) => i === "Festivals"
                  ) && (
                    <Link href="/explore/festivals">
                      <a className="relative mx-1 sm:w-200px w-85px cursor-pointer sm:mb-0 mb-4">
                        <div className="flex flex-col items-center bg-gradient-to-b from-gradient4 to-gradient5 sm:h-120px h-79px sm:pt-6 pt-4 sm:px-12 px-0 rounded-t-lg">
                          <div className="md:text-xl text-xs font-bold text-white uppercase text-center sm:mb-14px mb-3">
                            {t("key_web_festivals")}
                          </div>
                          <div className="md:block hidden">
                            <Image
                              width={28}
                              height={28}
                              src="/icons/ic_search_festival_web.png"
                              alt="festival"
                            />
                          </div>
                          <div className="md:hidden block">
                            <Image
                              width={20}
                              height={20}
                              src="/icons/ic_search_festival_web.png"
                              alt="festival"
                            />
                          </div>
                        </div>
                        <div className="bg-black4 flex justify-center items-center sm:h-55px h-auto py-2.5 rounded-b-lg">
                          <div className="md:text-xl text-xs font-bold text-white text-center">
                            {festivalCount}
                          </div>
                        </div>
                      </a>
                    </Link>
                  )}
                  {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
                    (i) => i === "Events"
                  ) && (
                    <Link href="/explore/events">
                      <a className="relative mx-1 sm:w-200px w-85px cursor-pointer sm:mb-0 mb-4">
                        <div className="flex flex-col items-center bg-gradient-to-b from-gradient4 to-gradient5 sm:h-120px h-79px sm:pt-6 pt-4 sm:px-12 px-0 rounded-t-lg">
                          <div className="md:text-xl text-xs font-bold text-white uppercase text-center sm:mb-14px mb-3">
                            {t("key_web_events")}
                          </div>
                          <div className="md:block hidden">
                            <Image
                              width={28}
                              height={28}
                              src="/icons/ic_search_event_web.png"
                              alt="event"
                            />
                          </div>
                          <div className="md:hidden block">
                            <Image
                              width={20}
                              height={20}
                              src="/icons/ic_search_event_web.png"
                              alt="event"
                            />
                          </div>
                        </div>
                        <div className="bg-black4 flex justify-center items-center sm:h-55px h-auto py-2.5 rounded-b-lg">
                          <div className="md:text-xl text-xs font-bold text-white text-center">
                            {eventCount}
                          </div>
                        </div>
                      </a>
                    </Link>
                  )}
                  {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
                    (i) => i === "Artists"
                  ) && (
                    <Link href="/explore/artists">
                      <a className="relative mx-1 sm:w-200px w-85px cursor-pointer sm:mb-0 mb-4">
                        <div className="flex flex-col items-center bg-gradient-to-b from-gradient4 to-gradient5 sm:h-120px h-79px sm:pt-6 pt-4 sm:px-12 px-0 rounded-t-lg">
                          <div className="md:text-xl text-xs font-bold text-white uppercase text-center sm:mb-15px mb-3">
                            {t("key_web_artists")}
                          </div>
                          <div className="md:block hidden">
                            <Image
                              width={21}
                              height={28}
                              src="/icons/ic_search_artist_web.png"
                              alt="artist"
                            />
                          </div>
                          <div className="md:hidden block">
                            <Image
                              width={15}
                              height={20}
                              src="/icons/ic_search_artist_web.png"
                              alt="artist"
                            />
                          </div>
                        </div>
                        <div className="bg-black4 flex justify-center items-center sm:h-55px h-auto py-2.5 rounded-b-lg">
                          <div className="md:text-xl text-xs font-bold text-white text-center">
                            {artistCount}
                          </div>
                        </div>
                      </a>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Listing Section */}
      <div className={`${getClass("bg-black", true)} md:block hidden`}>
        <div className="wrapper mx-auto h-auto md:py-87px sm:py-20 py-10">
          <div className="mx-auto 2xl:max-w-1545px max-w-1280px relative">
            {/* Venue */}
            {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
              (i) => i === "Venues"
            ) && (
              <div className="relative">
                <div className="flex flex-row sm:items-center justify-between px-16">
                  <div className="flex sm:flex-row flex-col sm:items-center">
                    <div
                      className={`text-2xl font-bold ${getClass(
                        "text-white",
                        true
                      )} sm:mr-7 sm:mb-0 mb-2`}>
                      VENUES
                    </div>
                    {/* <div className="flex flex-row items-center">
                      <div
                        className={`text-base font-medium border-b-2 cursor-pointer pb-5px mr-7 ${
                          venueSort ===
                          Constants.sortByAction.SORT_BY_RECOMMENDED
                            ? "border-white text-white"
                            : "border-transparent text-white3"
                        }`}
                        onClick={() =>
                          setVenueSort(
                            Constants.sortByAction.SORT_BY_RECOMMENDED
                          )
                        }>
                        Recommended
                      </div>
                    </div>
                    <div className="flex flex-row items-center">
                      <div
                        className={`text-base font-medium border-b-2 cursor-pointer pb-5px mr-7 ${
                          venueSort === Constants.sortByAction.SORT_BY_TRENDING
                            ? "border-white text-white"
                            : "border-transparent text-white3"
                        }`}
                        onClick={() =>
                          setVenueSort(Constants.sortByAction.SORT_BY_TRENDING)
                        }>
                        Trending
                      </div>
                    </div> */}
                  </div>
                  <Link href="/explore/venues">
                    <a
                      className={`text-xl font-bold ${getClass(
                        "text-white",
                        true
                      )} uppercase cursor-pointer`}>
                      Discover
                    </a>
                  </Link>
                </div>
                {venueList.length > 4 && (
                  <div className="absolute z-10 top-40 w-full flex flex-row justify-between">
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        if (pageVenue - 1 >= 0) {
                          pageVenue--;
                        }
                        const newIndex = pageVenue * 4;
                        flatListVenueRef?.current?.scrollToIndex({
                          animated: true,
                          index: newIndex,
                        });
                      }}>
                      <img
                        src={`/icons/${
                          process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "LuxResort Club" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "AparthotelClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
                            ? process.env.NEXT_PUBLIC_APP_NAME?.replace(
                                " ",
                                ""
                              )?.toLowerCase() + "/"
                            : ""
                        }ic_arrow_left.png`}
                        width="40"
                        height="40"
                        alt="arrow"
                      />
                    </div>
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        if (pageVenue + 1 < Math.round(venueList.length / 4)) {
                          pageVenue++;
                        }
                        const newIndex = pageVenue * 4;
                        flatListVenueRef?.current?.scrollToIndex({
                          animated: true,
                          index: newIndex,
                        });
                      }}>
                      <img
                        src={`/icons/${
                          process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "LuxResort Club" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "AparthotelClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
                            ? process.env.NEXT_PUBLIC_APP_NAME?.replace(
                                " ",
                                ""
                              )?.toLowerCase() + "/"
                            : ""
                        }ic_arrow_right.png`}
                        width="40"
                        height="40"
                        alt="arrow"
                      />
                    </div>
                  </div>
                )}
                <CardList
                  ref={flatListVenueRef}
                  horizontal={true}
                  items={venueList}
                  category={Constants.categoryType.CATEGORY_VENUE}
                  style={{ marginTop: 30, paddingHorizontal: 54 }}
                  withoutBadge={true}
                  // onItemAction={({ type, item }) => {
                  //   if (type == Constants.actionType.ACTION_VIEW) {
                  //     var profileId = item.profileId || item.id;
                  //     router.push({
                  //       pathname: "/profile/details",
                  //       query: {
                  //         profileId: profileId,
                  //         category: Constants.categoryType.CATEGORY_FESTIVAL,
                  //         id: item.id,
                  //       },
                  //     });
                  //   }
                  // }}
                />
                {isLoadingVenue && <ActivityIndicator />}
              </div>
            )}

            {/* Rooftop */}
            {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
              (i) => i === "Rooftops"
            ) && (
              <div className="relative">
                <div className="flex flex-row sm:items-center justify-between px-16">
                  <div className="flex sm:flex-row flex-col sm:items-center">
                    <div
                      className={`text-2xl font-bold ${getClass(
                        "text-white",
                        true
                      )} sm:mr-7 sm:mb-0 mb-2`}>
                      ROOFTOPS
                    </div>
                    {/* <div className="flex flex-row items-center">
                      <div
                        className={`text-base font-medium border-b-2 cursor-pointer pb-5px mr-7 ${
                          rooftopSort ===
                          Constants.sortByAction.SORT_BY_RECOMMENDED
                            ? "border-white text-white"
                            : "border-transparent text-white3"
                        }`}
                        onClick={() =>
                          setRooftopSort(
                            Constants.sortByAction.SORT_BY_RECOMMENDED
                          )
                        }>
                        Recommended
                      </div>
                    </div>
                    <div className="flex flex-row items-center">
                      <div
                        className={`text-base font-medium border-b-2 cursor-pointer pb-5px mr-7 ${
                          rooftopSort ===
                          Constants.sortByAction.SORT_BY_TRENDING
                            ? "border-white text-white"
                            : "border-transparent text-white3"
                        }`}
                        onClick={() =>
                          setRooftopSort(
                            Constants.sortByAction.SORT_BY_TRENDING
                          )
                        }>
                        Trending
                      </div>
                    </div> */}
                  </div>
                  <Link href="/explore/rooftops">
                    <a
                      className={`text-xl font-bold ${getClass(
                        "text-white",
                        true
                      )} uppercase cursor-pointer`}>
                      Discover
                    </a>
                  </Link>
                </div>
                {rooftopList.length > 4 && (
                  <div className="absolute z-10 top-40 w-full flex flex-row justify-between">
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        if (pageRooftop - 1 >= 0) {
                          pageRooftop--;
                        }
                        const newIndex = pageRooftop * 4;
                        flatListRooftopRef?.current?.scrollToIndex({
                          animated: true,
                          index: newIndex,
                        });
                      }}>
                      <img
                        src={`/icons/${
                          process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "LuxResort Club" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "AparthotelClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
                            ? process.env.NEXT_PUBLIC_APP_NAME?.replace(
                                " ",
                                ""
                              )?.toLowerCase() + "/"
                            : ""
                        }ic_arrow_left.png`}
                        width="40"
                        height="40"
                        alt="arrow"
                      />
                    </div>
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        if (
                          pageRooftop + 1 <
                          Math.round(rooftopList.length / 4)
                        ) {
                          pageRooftop++;
                        }
                        const newIndex = pageRooftop * 4;
                        flatListRooftopRef?.current?.scrollToIndex({
                          animated: true,
                          index: newIndex,
                        });
                      }}>
                      <img
                        src={`/icons/${
                          process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "LuxResort Club" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "AparthotelClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
                            ? process.env.NEXT_PUBLIC_APP_NAME?.replace(
                                " ",
                                ""
                              )?.toLowerCase() + "/"
                            : ""
                        }ic_arrow_right.png`}
                        width="40"
                        height="40"
                        alt="arrow"
                      />
                    </div>
                  </div>
                )}
                <CardList
                  ref={flatListRooftopRef}
                  horizontal={true}
                  items={rooftopList}
                  category={Constants.categoryType.CATEGORY_ROOFTOP}
                  style={{ marginTop: 30, paddingHorizontal: 54 }}
                  withoutBadge={true}
                  // onItemAction={({ type, item }) => {
                  //   if (type == Constants.actionType.ACTION_VIEW) {
                  //     var profileId = item.profileId || item.id;
                  //     router.push({
                  //       pathname: "/profile/details",
                  //       query: {
                  //         profileId: profileId,
                  //         category: Constants.categoryType.CATEGORY_FESTIVAL,
                  //         id: item.id,
                  //       },
                  //     });
                  //   }
                  // }}
                />
                {isLoadingRooftop && <ActivityIndicator />}
              </div>
            )}

            {/* Spa */}
            {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
              (i) => i === "Spas"
            ) && (
              <div className="relative">
                <div className="flex flex-row sm:items-center justify-between px-16">
                  <div className="flex sm:flex-row flex-col sm:items-center">
                    <div
                      className={`text-2xl font-bold ${getClass(
                        "text-white",
                        true
                      )} sm:mr-7 sm:mb-0 mb-2`}>
                      SPAS
                    </div>
                    {/* <div className="flex flex-row items-center">
                      <div
                        className={`text-base font-medium border-b-2 cursor-pointer pb-5px mr-7 ${
                          spaSort === Constants.sortByAction.SORT_BY_RECOMMENDED
                            ? "border-white text-white"
                            : "border-transparent text-white3"
                        }`}
                        onClick={() =>
                          setSpaSort(Constants.sortByAction.SORT_BY_RECOMMENDED)
                        }>
                        Recommended
                      </div>
                    </div>
                    <div className="flex flex-row items-center">
                      <div
                        className={`text-base font-medium border-b-2 cursor-pointer pb-5px mr-7 ${
                          spaSort === Constants.sortByAction.SORT_BY_TRENDING
                            ? "border-white text-white"
                            : "border-transparent text-white3"
                        }`}
                        onClick={() =>
                          setSpaSort(Constants.sortByAction.SORT_BY_TRENDING)
                        }>
                        Trending
                      </div>
                    </div> */}
                  </div>
                  <Link href="/explore/spas">
                    <a
                      className={`text-xl font-bold ${getClass(
                        "text-white",
                        true
                      )} uppercase cursor-pointer`}>
                      Discover
                    </a>
                  </Link>
                </div>
                {spaList.length > 4 && (
                  <div className="absolute z-10 top-40 w-full flex flex-row justify-between">
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        if (pageSpa - 1 >= 0) {
                          pageSpa--;
                        }
                        const newIndex = pageSpa * 4;
                        flatListSpaRef?.current?.scrollToIndex({
                          animated: true,
                          index: newIndex,
                        });
                      }}>
                      <img
                        src={`/icons/${
                          process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "LuxResort Club" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "AparthotelClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
                            ? process.env.NEXT_PUBLIC_APP_NAME?.replace(
                                " ",
                                ""
                              )?.toLowerCase() + "/"
                            : ""
                        }ic_arrow_left.png`}
                        width="40"
                        height="40"
                        alt="arrow"
                      />
                    </div>
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        if (pageSpa + 1 < Math.round(SpaList.length / 4)) {
                          pageSpa++;
                        }
                        const newIndex = pageSpa * 4;
                        flatListSpaRef?.current?.scrollToIndex({
                          animated: true,
                          index: newIndex,
                        });
                      }}>
                      <img
                        src={`/icons/${
                          process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "LuxResort Club" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "AparthotelClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
                            ? process.env.NEXT_PUBLIC_APP_NAME?.replace(
                                " ",
                                ""
                              )?.toLowerCase() + "/"
                            : ""
                        }ic_arrow_right.png`}
                        width="40"
                        height="40"
                        alt="arrow"
                      />
                    </div>
                  </div>
                )}
                <CardList
                  ref={flatListSpaRef}
                  horizontal={true}
                  items={spaList}
                  category={Constants.categoryType.CATEGORY_SPA}
                  style={{ marginTop: 30, paddingHorizontal: 54 }}
                  withoutBadge={true}
                  // onItemAction={({ type, item }) => {
                  //   if (type == Constants.actionType.ACTION_VIEW) {
                  //     var profileId = item.profileId || item.id;
                  //     router.push({
                  //       pathname: "/profile/details",
                  //       query: {
                  //         profileId: profileId,
                  //         category: Constants.categoryType.CATEGORY_FESTIVAL,
                  //         id: item.id,
                  //       },
                  //     });
                  //   }
                  // }}
                />
                {isLoadingSpa && <ActivityIndicator />}
              </div>
            )}

            {/* Venue Beachful */}
            {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
              (i) => i === "Venue Beachful"
            ) && (
              <div className="relative">
                <div className="flex flex-row sm:items-center justify-between px-16">
                  <div className="flex sm:flex-row flex-col sm:items-center">
                    <div
                      className={`text-2xl font-bold ${getClass(
                        "text-white",
                        true
                      )} sm:mr-7 sm:mb-0 mb-2`}>
                      BEACH CLUBS
                    </div>
                    {/* <div className="flex flex-row items-center">
                      <div
                        className={`text-base font-medium border-b-2 cursor-pointer pb-5px mr-7 ${
                          venueBeachfulSort ===
                          Constants.sortByAction.SORT_BY_RECOMMENDED
                            ? "border-white text-white"
                            : "border-transparent text-white3"
                        }`}
                        onClick={() =>
                          setVenueBeachfulSort(
                            Constants.sortByAction.SORT_BY_RECOMMENDED
                          )
                        }>
                        Recommended
                      </div>
                    </div>
                    <div className="flex flex-row items-center">
                      <div
                        className={`text-base font-medium border-b-2 cursor-pointer pb-5px mr-7 ${
                          venueBeachfulSort ===
                          Constants.sortByAction.SORT_BY_TRENDING
                            ? "border-white text-white"
                            : "border-transparent text-white3"
                        }`}
                        onClick={() =>
                          setVenueBeachfulSort(
                            Constants.sortByAction.SORT_BY_TRENDING
                          )
                        }>
                        Trending
                      </div>
                    </div> */}
                  </div>
                  <Link href="/explore/beach-clubs">
                    <a
                      className={`text-xl font-bold ${getClass(
                        "text-white",
                        true
                      )} uppercase cursor-pointer`}>
                      Discover
                    </a>
                  </Link>
                </div>
                {venueBeachfulList.length > 4 && (
                  <div className="absolute z-10 top-40 w-full flex flex-row justify-between">
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        if (pageVenueBeachful - 1 >= 0) {
                          pageVenueBeachful--;
                        }
                        const newIndex = pageVenueBeachful * 4;
                        flatListVenueBeachfulRef?.current?.scrollToIndex({
                          animated: true,
                          index: newIndex,
                        });
                      }}>
                      <img
                        src={`/icons/${
                          process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "LuxResort Club" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "AparthotelClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
                            ? process.env.NEXT_PUBLIC_APP_NAME?.replace(
                                " ",
                                ""
                              )?.toLowerCase() + "/"
                            : ""
                        }ic_arrow_left.png`}
                        width="40"
                        height="40"
                        alt="arrow"
                      />
                    </div>
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        if (
                          pageVenueBeachful + 1 <
                          Math.round(venueBeachfulList.length / 4)
                        ) {
                          pageVenueBeachful++;
                        }
                        const newIndex = pageVenueBeachful * 4;
                        flatListVenueBeachfulRef?.current?.scrollToIndex({
                          animated: true,
                          index: newIndex,
                        });
                      }}>
                      <img
                        src={`/icons/${
                          process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "LuxResort Club" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "AparthotelClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
                            ? process.env.NEXT_PUBLIC_APP_NAME?.replace(
                                " ",
                                ""
                              )?.toLowerCase() + "/"
                            : ""
                        }ic_arrow_right.png`}
                        width="40"
                        height="40"
                        alt="arrow"
                      />
                    </div>
                  </div>
                )}
                <CardList
                  ref={flatListVenueBeachfulRef}
                  horizontal={true}
                  items={venueBeachfulList}
                  category={Constants.categoryType.CATEGORY_VENUE_BEACHFUL}
                  style={{ marginTop: 30, paddingHorizontal: 54 }}
                  withoutBadge={true}
                  // onItemAction={({ type, item }) => {
                  //   if (type == Constants.actionType.ACTION_VIEW) {
                  //     var profileId = item.profileId || item.id;
                  //     router.push({
                  //       pathname: "/profile/details",
                  //       query: {
                  //         profileId: profileId,
                  //         category: Constants.categoryType.CATEGORY_FESTIVAL,
                  //         id: item.id,
                  //       },
                  //     });
                  //   }
                  // }}
                />
                {isLoadingVenueBeachful && <ActivityIndicator />}
              </div>
            )}

            {/* Prefab Home */}
            {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
              (i) => i === "Prefab Home"
            ) && (
              <div className="relative">
                <div className="flex flex-row sm:items-center justify-between px-16">
                  <div className="flex sm:flex-row flex-col sm:items-center">
                    <div
                      className={`text-2xl font-bold ${getClass(
                        "text-white",
                        true
                      )} sm:mr-7 sm:mb-0 mb-2`}>
                      HOMES
                    </div>
                    {/* <div className="flex flex-row items-center">
                      <div
                        className={`text-base font-medium border-b-2 cursor-pointer pb-5px mr-7 ${
                          venueBeachfulSort ===
                          Constants.sortByAction.SORT_BY_RECOMMENDED
                            ? "border-white text-white"
                            : "border-transparent text-white3"
                        }`}
                        onClick={() =>
                          setVenueBeachfulSort(
                            Constants.sortByAction.SORT_BY_RECOMMENDED
                          )
                        }>
                        Recommended
                      </div>
                    </div>
                    <div className="flex flex-row items-center">
                      <div
                        className={`text-base font-medium border-b-2 cursor-pointer pb-5px mr-7 ${
                          venueBeachfulSort ===
                          Constants.sortByAction.SORT_BY_TRENDING
                            ? "border-white text-white"
                            : "border-transparent text-white3"
                        }`}
                        onClick={() =>
                          setVenueBeachfulSort(
                            Constants.sortByAction.SORT_BY_TRENDING
                          )
                        }>
                        Trending
                      </div>
                    </div> */}
                  </div>
                  <Link href="/explore/homes">
                    <a
                      className={`text-xl font-bold ${getClass(
                        "text-white",
                        true
                      )} uppercase cursor-pointer`}>
                      Discover
                    </a>
                  </Link>
                </div>
                {prefabHomeList.length > 4 && (
                  <div className="absolute z-10 top-40 w-full flex flex-row justify-between">
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        if (pagePrefabHome - 1 >= 0) {
                          pagePrefabHome--;
                        }
                        const newIndex = pagePrefabHome * 4;
                        flatListPrefabHomeRef?.current?.scrollToIndex({
                          animated: true,
                          index: newIndex,
                        });
                      }}>
                      <img
                        src={`/icons/${
                          process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "LuxResort Club" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "AparthotelClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
                            ? process.env.NEXT_PUBLIC_APP_NAME?.replace(
                                " ",
                                ""
                              )?.toLowerCase() + "/"
                            : ""
                        }ic_arrow_left.png`}
                        width="40"
                        height="40"
                        alt="arrow"
                      />
                    </div>
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        if (
                          pagePrefabHome + 1 <
                          Math.round(prefabHomeList.length / 4)
                        ) {
                          pagePrefabHome++;
                        }
                        const newIndex = pagePrefabHome * 4;
                        flatListPrefabHomeRef?.current?.scrollToIndex({
                          animated: true,
                          index: newIndex,
                        });
                      }}>
                      <img
                        src={`/icons/${
                          process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "LuxResort Club" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "AparthotelClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
                            ? process.env.NEXT_PUBLIC_APP_NAME?.replace(
                                " ",
                                ""
                              )?.toLowerCase() + "/"
                            : ""
                        }ic_arrow_right.png`}
                        width="40"
                        height="40"
                        alt="arrow"
                      />
                    </div>
                  </div>
                )}
                <CardList
                  ref={flatListPrefabHomeRef}
                  horizontal={true}
                  items={prefabHomeList}
                  category={Constants.categoryType.CATEGORY_PREFAB_HOMES}
                  style={{ marginTop: 30, paddingHorizontal: 54 }}
                  withoutBadge={true}
                  // onItemAction={({ type, item }) => {
                  //   if (type == Constants.actionType.ACTION_VIEW) {
                  //     var profileId = item.profileId || item.id;
                  //     router.push({
                  //       pathname: "/profile/details",
                  //       query: {
                  //         profileId: profileId,
                  //         category: Constants.categoryType.CATEGORY_FESTIVAL,
                  //         id: item.id,
                  //       },
                  //     });
                  //   }
                  // }}
                />
                {isLoadingPrefabHome && <ActivityIndicator />}
              </div>
            )}

            {/* Prefab Company */}
            {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
              (i) => i === "Prefab Company"
            ) && (
              <div className="relative">
                <div className="flex flex-row sm:items-center justify-between px-16 mt-14">
                  <div className="flex sm:flex-row flex-col sm:items-center">
                    <div
                      className={`text-2xl font-bold ${getClass(
                        "text-white",
                        true
                      )} sm:mr-7 sm:mb-0 mb-2`}>
                      COMPANIES
                    </div>
                    {/* <div className="flex flex-row items-center">
                      <div
                        className={`text-base font-medium border-b-2 cursor-pointer pb-5px mr-7 ${
                          venueBeachfulSort ===
                          Constants.sortByAction.SORT_BY_RECOMMENDED
                            ? "border-white text-white"
                            : "border-transparent text-white3"
                        }`}
                        onClick={() =>
                          setVenueBeachfulSort(
                            Constants.sortByAction.SORT_BY_RECOMMENDED
                          )
                        }>
                        Recommended
                      </div>
                    </div>
                    <div className="flex flex-row items-center">
                      <div
                        className={`text-base font-medium border-b-2 cursor-pointer pb-5px mr-7 ${
                          venueBeachfulSort ===
                          Constants.sortByAction.SORT_BY_TRENDING
                            ? "border-white text-white"
                            : "border-transparent text-white3"
                        }`}
                        onClick={() =>
                          setVenueBeachfulSort(
                            Constants.sortByAction.SORT_BY_TRENDING
                          )
                        }>
                        Trending
                      </div>
                    </div> */}
                  </div>
                  <Link href="/explore/companies">
                    <a
                      className={`text-xl font-bold ${getClass(
                        "text-white",
                        true
                      )} uppercase cursor-pointer`}>
                      Discover
                    </a>
                  </Link>
                </div>
                {prefabCompanyList.length > 4 && (
                  <div className="absolute z-10 top-40 w-full flex flex-row justify-between">
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        if (pagePrefabCompany - 1 >= 0) {
                          pagePrefabCompany--;
                        }
                        const newIndex = pagePrefabCompany * 4;
                        flatListPrefabCompanyRef?.current?.scrollToIndex({
                          animated: true,
                          index: newIndex,
                        });
                      }}>
                      <img
                        src={`/icons/${
                          process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "LuxResort Club" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "AparthotelClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
                            ? process.env.NEXT_PUBLIC_APP_NAME?.replace(
                                " ",
                                ""
                              )?.toLowerCase() + "/"
                            : ""
                        }ic_arrow_left.png`}
                        width="40"
                        height="40"
                        alt="arrow"
                      />
                    </div>
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        if (
                          pagePrefabCompany + 1 <
                          Math.round(prefabCompanyList.length / 4)
                        ) {
                          pagePrefabCompany++;
                        }
                        const newIndex = pagePrefabCompany * 4;
                        flatListPrefabCompanyRef?.current?.scrollToIndex({
                          animated: true,
                          index: newIndex,
                        });
                      }}>
                      <img
                        src={`/icons/${
                          process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "LuxResort Club" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "AparthotelClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
                            ? process.env.NEXT_PUBLIC_APP_NAME?.replace(
                                " ",
                                ""
                              )?.toLowerCase() + "/"
                            : ""
                        }ic_arrow_right.png`}
                        width="40"
                        height="40"
                        alt="arrow"
                      />
                    </div>
                  </div>
                )}
                <CardList
                  ref={flatListPrefabCompanyRef}
                  horizontal={true}
                  items={prefabCompanyList}
                  category={Constants.categoryType.CATEGORY_PREFAB_COMPANY}
                  style={{ marginTop: 30, paddingHorizontal: 54 }}
                  withoutBadge={true}
                  // onItemAction={({ type, item }) => {
                  //   if (type == Constants.actionType.ACTION_VIEW) {
                  //     var profileId = item.profileId || item.id;
                  //     router.push({
                  //       pathname: "/profile/details",
                  //       query: {
                  //         profileId: profileId,
                  //         category: Constants.categoryType.CATEGORY_FESTIVAL,
                  //         id: item.id,
                  //       },
                  //     });
                  //   }
                  // }}
                />
                {isLoadingPrefabCompany && <ActivityIndicator />}
              </div>
            )}

            {/* Zoo */}
            {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
              (i) => i === "Zoos"
            ) && (
              <div className="relative">
                <div className="flex flex-row sm:items-center justify-between px-16">
                  <div className="flex sm:flex-row flex-col sm:items-center">
                    <div
                      className={`text-2xl font-bold ${getClass(
                        "text-white",
                        true
                      )} sm:mr-7 sm:mb-0 mb-2`}>
                      ZOOS
                    </div>
                    {/* <div className="flex flex-row items-center">
                      <div
                        className={`text-base font-medium border-b-2 cursor-pointer pb-5px mr-7 ${
                          venueBeachfulSort ===
                          Constants.sortByAction.SORT_BY_RECOMMENDED
                            ? "border-white text-white"
                            : "border-transparent text-white3"
                        }`}
                        onClick={() =>
                          setVenueBeachfulSort(
                            Constants.sortByAction.SORT_BY_RECOMMENDED
                          )
                        }>
                        Recommended
                      </div>
                    </div>
                    <div className="flex flex-row items-center">
                      <div
                        className={`text-base font-medium border-b-2 cursor-pointer pb-5px mr-7 ${
                          venueBeachfulSort ===
                          Constants.sortByAction.SORT_BY_TRENDING
                            ? "border-white text-white"
                            : "border-transparent text-white3"
                        }`}
                        onClick={() =>
                          setVenueBeachfulSort(
                            Constants.sortByAction.SORT_BY_TRENDING
                          )
                        }>
                        Trending
                      </div>
                    </div> */}
                  </div>
                  <Link href="/explore/zoos">
                    <a
                      className={`text-xl font-bold ${getClass(
                        "text-white",
                        true
                      )} uppercase cursor-pointer`}>
                      Discover
                    </a>
                  </Link>
                </div>
                {zooList.length > 4 && (
                  <div className="absolute z-10 top-40 w-full flex flex-row justify-between">
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        if (pageZoo - 1 >= 0) {
                          pageZoo--;
                        }
                        const newIndex = pageZoo * 4;
                        flatListZooRef?.current?.scrollToIndex({
                          animated: true,
                          index: newIndex,
                        });
                      }}>
                      <img
                        src={`/icons/${
                          process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "LuxResort Club" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "AparthotelClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
                            ? process.env.NEXT_PUBLIC_APP_NAME?.replace(
                                " ",
                                ""
                              )?.toLowerCase() + "/"
                            : ""
                        }ic_arrow_left.png`}
                        width="40"
                        height="40"
                        alt="arrow"
                      />
                    </div>
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        if (pageZoo + 1 < Math.round(zooList.length / 4)) {
                          pageZoo++;
                        }
                        const newIndex = pageZoo * 4;
                        flatListZooRef?.current?.scrollToIndex({
                          animated: true,
                          index: newIndex,
                        });
                      }}>
                      <img
                        src={`/icons/${
                          process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "LuxResort Club" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "AparthotelClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
                            ? process.env.NEXT_PUBLIC_APP_NAME?.replace(
                                " ",
                                ""
                              )?.toLowerCase() + "/"
                            : ""
                        }ic_arrow_right.png`}
                        width="40"
                        height="40"
                        alt="arrow"
                      />
                    </div>
                  </div>
                )}
                <CardList
                  ref={flatListZooRef}
                  horizontal={true}
                  items={zooList}
                  category={Constants.categoryType.CATEGORY_ZOO}
                  style={{ marginTop: 30, paddingHorizontal: 54 }}
                  withoutBadge={true}
                  // onItemAction={({ type, item }) => {
                  //   if (type == Constants.actionType.ACTION_VIEW) {
                  //     var profileId = item.profileId || item.id;
                  //     router.push({
                  //       pathname: "/profile/details",
                  //       query: {
                  //         profileId: profileId,
                  //         category: Constants.categoryType.CATEGORY_FESTIVAL,
                  //         id: item.id,
                  //       },
                  //     });
                  //   }
                  // }}
                />
                {isLoadingZoo && <ActivityIndicator />}
              </div>
            )}

            {/* Animal */}
            {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
              (i) => i === "Animals"
            ) && (
              <div className="relative">
                <div className="flex flex-row sm:items-center justify-between px-16 mt-14">
                  <div className="flex sm:flex-row flex-col sm:items-center">
                    <div
                      className={`text-2xl font-bold ${getClass(
                        "text-white",
                        true
                      )} sm:mr-7 sm:mb-0 mb-2`}>
                      ANIMALS
                    </div>
                    {/* <div className="flex flex-row items-center">
                      <div
                        className={`text-base font-medium border-b-2 cursor-pointer pb-5px mr-7 ${
                          venueBeachfulSort ===
                          Constants.sortByAction.SORT_BY_RECOMMENDED
                            ? "border-white text-white"
                            : "border-transparent text-white3"
                        }`}
                        onClick={() =>
                          setVenueBeachfulSort(
                            Constants.sortByAction.SORT_BY_RECOMMENDED
                          )
                        }>
                        Recommended
                      </div>
                    </div>
                    <div className="flex flex-row items-center">
                      <div
                        className={`text-base font-medium border-b-2 cursor-pointer pb-5px mr-7 ${
                          venueBeachfulSort ===
                          Constants.sortByAction.SORT_BY_TRENDING
                            ? "border-white text-white"
                            : "border-transparent text-white3"
                        }`}
                        onClick={() =>
                          setVenueBeachfulSort(
                            Constants.sortByAction.SORT_BY_TRENDING
                          )
                        }>
                        Trending
                      </div>
                    </div> */}
                  </div>
                  <Link href="/explore/animals">
                    <a
                      className={`text-xl font-bold ${getClass(
                        "text-white",
                        true
                      )} uppercase cursor-pointer`}>
                      Discover
                    </a>
                  </Link>
                </div>
                {animalList.length > 4 && (
                  <div className="absolute z-10 top-40 w-full flex flex-row justify-between">
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        if (pageAnimal - 1 >= 0) {
                          pageAnimal--;
                        }
                        const newIndex = pageAnimal * 4;
                        flatListAnimalRef?.current?.scrollToIndex({
                          animated: true,
                          index: newIndex,
                        });
                      }}>
                      <img
                        src={`/icons/${
                          process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "LuxResort Club" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "AparthotelClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
                            ? process.env.NEXT_PUBLIC_APP_NAME?.replace(
                                " ",
                                ""
                              )?.toLowerCase() + "/"
                            : ""
                        }ic_arrow_left.png`}
                        width="40"
                        height="40"
                        alt="arrow"
                      />
                    </div>
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        if (
                          pageAnimal + 1 <
                          Math.round(animalList.length / 4)
                        ) {
                          pageAnimal++;
                        }
                        const newIndex = pageAnimal * 4;
                        flatListAnimalRef?.current?.scrollToIndex({
                          animated: true,
                          index: newIndex,
                        });
                      }}>
                      <img
                        src={`/icons/${
                          process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "LuxResort Club" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "AparthotelClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
                            ? process.env.NEXT_PUBLIC_APP_NAME?.replace(
                                " ",
                                ""
                              )?.toLowerCase() + "/"
                            : ""
                        }ic_arrow_right.png`}
                        width="40"
                        height="40"
                        alt="arrow"
                      />
                    </div>
                  </div>
                )}
                <CardList
                  ref={flatListAnimalRef}
                  horizontal={true}
                  items={animalList}
                  category={Constants.categoryType.CATEGORY_ANIMAL}
                  style={{ marginTop: 30, paddingHorizontal: 54 }}
                  withoutBadge={true}
                  // onItemAction={({ type, item }) => {
                  //   if (type == Constants.actionType.ACTION_VIEW) {
                  //     var profileId = item.profileId || item.id;
                  //     router.push({
                  //       pathname: "/profile/details",
                  //       query: {
                  //         profileId: profileId,
                  //         category: Constants.categoryType.CATEGORY_FESTIVAL,
                  //         id: item.id,
                  //       },
                  //     });
                  //   }
                  // }}
                />
                {isLoadingAnimal && <ActivityIndicator />}
              </div>
            )}

            {/* Parks */}
            {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
              (i) => i === "Parks"
            ) && (
              <div className="relative">
                <div className="flex flex-row sm:items-center justify-between px-16">
                  <div className="flex sm:flex-row flex-col sm:items-center">
                    <div
                      className={`text-2xl font-bold ${getClass(
                        "text-white",
                        true
                      )} sm:mr-7 sm:mb-0 mb-2`}>
                      PARKS
                    </div>
                    {/* <div className="flex flex-row items-center">
                      <div
                        className={`text-base font-medium border-b-2 cursor-pointer pb-5px mr-7 ${
                          venueBeachfulSort ===
                          Constants.sortByAction.SORT_BY_RECOMMENDED
                            ? "border-white text-white"
                            : "border-transparent text-white3"
                        }`}
                        onClick={() =>
                          setVenueBeachfulSort(
                            Constants.sortByAction.SORT_BY_RECOMMENDED
                          )
                        }>
                        Recommended
                      </div>
                    </div>
                    <div className="flex flex-row items-center">
                      <div
                        className={`text-base font-medium border-b-2 cursor-pointer pb-5px mr-7 ${
                          venueBeachfulSort ===
                          Constants.sortByAction.SORT_BY_TRENDING
                            ? "border-white text-white"
                            : "border-transparent text-white3"
                        }`}
                        onClick={() =>
                          setVenueBeachfulSort(
                            Constants.sortByAction.SORT_BY_TRENDING
                          )
                        }>
                        Trending
                      </div>
                    </div> */}
                  </div>
                  <Link href="/explore/parks">
                    <a
                      className={`text-xl font-bold ${getClass(
                        "text-white",
                        true
                      )} uppercase cursor-pointer`}>
                      Discover
                    </a>
                  </Link>
                </div>
                {parkfulList.length > 4 && (
                  <div className="absolute z-10 top-40 w-full flex flex-row justify-between">
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        if (pageParkful - 1 >= 0) {
                          pageParkful--;
                        }
                        const newIndex = pageParkful * 4;
                        flatListParkfulRef?.current?.scrollToIndex({
                          animated: true,
                          index: newIndex,
                        });
                      }}>
                      <img
                        src={`/icons/${
                          process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "LuxResort Club" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "AparthotelClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
                            ? process.env.NEXT_PUBLIC_APP_NAME?.replace(
                                " ",
                                ""
                              )?.toLowerCase() + "/"
                            : ""
                        }ic_arrow_left.png`}
                        width="40"
                        height="40"
                        alt="arrow"
                      />
                    </div>
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        if (
                          pageParkful + 1 <
                          Math.round(parkfulList.length / 4)
                        ) {
                          pageParkful++;
                        }
                        const newIndex = pageParkful * 4;
                        flatListParkfulRef?.current?.scrollToIndex({
                          animated: true,
                          index: newIndex,
                        });
                      }}>
                      <img
                        src={`/icons/${
                          process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "LuxResort Club" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "AparthotelClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
                            ? process.env.NEXT_PUBLIC_APP_NAME?.replace(
                                " ",
                                ""
                              )?.toLowerCase() + "/"
                            : ""
                        }ic_arrow_right.png`}
                        width="40"
                        height="40"
                        alt="arrow"
                      />
                    </div>
                  </div>
                )}
                <CardList
                  ref={flatListParkfulRef}
                  horizontal={true}
                  items={parkfulList}
                  category={Constants.categoryType.CATEGORY_PARKFUL}
                  style={{ marginTop: 30, paddingHorizontal: 54 }}
                  withoutBadge={true}
                  // onItemAction={({ type, item }) => {
                  //   if (type == Constants.actionType.ACTION_VIEW) {
                  //     var profileId = item.profileId || item.id;
                  //     router.push({
                  //       pathname: "/profile/details",
                  //       query: {
                  //         profileId: profileId,
                  //         category: Constants.categoryType.CATEGORY_FESTIVAL,
                  //         id: item.id,
                  //       },
                  //     });
                  //   }
                  // }}
                />
                {isLoadingParkful && <ActivityIndicator />}
              </div>
            )}

            {/* Casinos */}
            {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
              (i) => i === "Casinos"
            ) && (
              <div className="relative">
                <div className="flex flex-row sm:items-center justify-between px-16">
                  <div className="flex sm:flex-row flex-col sm:items-center">
                    <div
                      className={`text-2xl font-bold ${getClass(
                        "text-white",
                        true
                      )} sm:mr-7 sm:mb-0 mb-2`}>
                      CASINOS
                    </div>
                    {/* <div className="flex flex-row items-center">
                      <div
                        className={`text-base font-medium border-b-2 cursor-pointer pb-5px mr-7 ${
                          venueBeachfulSort ===
                          Constants.sortByAction.SORT_BY_RECOMMENDED
                            ? "border-white text-white"
                            : "border-transparent text-white3"
                        }`}
                        onClick={() =>
                          setVenueBeachfulSort(
                            Constants.sortByAction.SORT_BY_RECOMMENDED
                          )
                        }>
                        Recommended
                      </div>
                    </div>
                    <div className="flex flex-row items-center">
                      <div
                        className={`text-base font-medium border-b-2 cursor-pointer pb-5px mr-7 ${
                          venueBeachfulSort ===
                          Constants.sortByAction.SORT_BY_TRENDING
                            ? "border-white text-white"
                            : "border-transparent text-white3"
                        }`}
                        onClick={() =>
                          setVenueBeachfulSort(
                            Constants.sortByAction.SORT_BY_TRENDING
                          )
                        }>
                        Trending
                      </div>
                    </div> */}
                  </div>
                  <Link href="/explore/casinos">
                    <a
                      className={`text-xl font-bold ${getClass(
                        "text-white",
                        true
                      )} uppercase cursor-pointer`}>
                      Discover
                    </a>
                  </Link>
                </div>
                {casinoList.length > 4 && (
                  <div className="absolute z-10 top-40 w-full flex flex-row justify-between">
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        if (pageCasino - 1 >= 0) {
                          pageCasino--;
                        }
                        const newIndex = pageCasino * 4;
                        flatListCasinoRef?.current?.scrollToIndex({
                          animated: true,
                          index: newIndex,
                        });
                      }}>
                      <img
                        src={`/icons/${
                          process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino"
                            ? process.env.NEXT_PUBLIC_APP_NAME?.replace(
                                " ",
                                ""
                              )?.toLowerCase() + "/"
                            : ""
                        }ic_arrow_left.png`}
                        width="40"
                        height="40"
                        alt="arrow"
                      />
                    </div>
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        if (
                          pageCasino + 1 <
                          Math.round(casinoList.length / 4)
                        ) {
                          pageCasino++;
                        }
                        const newIndex = pageCasino * 4;
                        flatListCasinoRef?.current?.scrollToIndex({
                          animated: true,
                          index: newIndex,
                        });
                      }}>
                      <img
                        src={`/icons/${
                          process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino"
                            ? process.env.NEXT_PUBLIC_APP_NAME?.replace(
                                " ",
                                ""
                              )?.toLowerCase() + "/"
                            : ""
                        }ic_arrow_right.png`}
                        width="40"
                        height="40"
                        alt="arrow"
                      />
                    </div>
                  </div>
                )}
                <CardList
                  ref={flatListCasinoRef}
                  horizontal={true}
                  items={casinoList}
                  category={Constants.categoryType.CATEGORY_CASINO}
                  style={{ marginTop: 30, paddingHorizontal: 54 }}
                  withoutBadge={true}
                  // onItemAction={({ type, item }) => {
                  //   if (type == Constants.actionType.ACTION_VIEW) {
                  //     var profileId = item.profileId || item.id;
                  //     router.push({
                  //       pathname: "/profile/details",
                  //       query: {
                  //         profileId: profileId,
                  //         category: Constants.categoryType.CATEGORY_FESTIVAL,
                  //         id: item.id,
                  //       },
                  //     });
                  //   }
                  // }}
                />
                {isLoadingCasino && <ActivityIndicator />}
              </div>
            )}

            {/* Castles */}
            {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
              (i) => i === "Castles"
            ) && (
              <div className="relative">
                <div className="flex flex-row sm:items-center justify-between px-16">
                  <div className="flex sm:flex-row flex-col sm:items-center">
                    <div
                      className={`text-2xl font-bold ${getClass(
                        "text-white",
                        true
                      )} sm:mr-7 sm:mb-0 mb-2`}>
                      CASTLES
                    </div>
                    {/* <div className="flex flex-row items-center">
                      <div
                        className={`text-base font-medium border-b-2 cursor-pointer pb-5px mr-7 ${
                          venueBeachfulSort ===
                          Constants.sortByAction.SORT_BY_RECOMMENDED
                            ? "border-white text-white"
                            : "border-transparent text-white3"
                        }`}
                        onClick={() =>
                          setVenueBeachfulSort(
                            Constants.sortByAction.SORT_BY_RECOMMENDED
                          )
                        }>
                        Recommended
                      </div>
                    </div>
                    <div className="flex flex-row items-center">
                      <div
                        className={`text-base font-medium border-b-2 cursor-pointer pb-5px mr-7 ${
                          venueBeachfulSort ===
                          Constants.sortByAction.SORT_BY_TRENDING
                            ? "border-white text-white"
                            : "border-transparent text-white3"
                        }`}
                        onClick={() =>
                          setVenueBeachfulSort(
                            Constants.sortByAction.SORT_BY_TRENDING
                          )
                        }>
                        Trending
                      </div>
                    </div> */}
                  </div>
                  <Link href="/explore/castles">
                    <a
                      className={`text-xl font-bold ${getClass(
                        "text-white",
                        true
                      )} uppercase cursor-pointer`}>
                      Discover
                    </a>
                  </Link>
                </div>
                {castleList.length > 4 && (
                  <div className="absolute z-10 top-40 w-full flex flex-row justify-between">
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        if (pageCastle - 1 >= 0) {
                          pageCastle--;
                        }
                        const newIndex = pageCastle * 4;
                        flatListCastleRef?.current?.scrollToIndex({
                          animated: true,
                          index: newIndex,
                        });
                      }}>
                      <img
                        src={`/icons/${
                          process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "LuxResort Club" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "AparthotelClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
                            ? process.env.NEXT_PUBLIC_APP_NAME?.replace(
                                " ",
                                ""
                              )?.toLowerCase() + "/"
                            : ""
                        }ic_arrow_left.png`}
                        width="40"
                        height="40"
                        alt="arrow"
                      />
                    </div>
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        if (
                          pageCastle + 1 <
                          Math.round(castleList.length / 4)
                        ) {
                          pageCastle++;
                        }
                        const newIndex = pageCastle * 4;
                        flatListCastleRef?.current?.scrollToIndex({
                          animated: true,
                          index: newIndex,
                        });
                      }}>
                      <img
                        src={`/icons/${
                          process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "LuxResort Club" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "AparthotelClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
                            ? process.env.NEXT_PUBLIC_APP_NAME?.replace(
                                " ",
                                ""
                              )?.toLowerCase() + "/"
                            : ""
                        }ic_arrow_right.png`}
                        width="40"
                        height="40"
                        alt="arrow"
                      />
                    </div>
                  </div>
                )}
                <CardList
                  ref={flatListCastleRef}
                  horizontal={true}
                  items={castleList}
                  category={Constants.categoryType.CATEGORY_CASTLE}
                  style={{ marginTop: 30, paddingHorizontal: 54 }}
                  withoutBadge={true}
                  // onItemAction={({ type, item }) => {
                  //   if (type == Constants.actionType.ACTION_VIEW) {
                  //     var profileId = item.profileId || item.id;
                  //     router.push({
                  //       pathname: "/profile/details",
                  //       query: {
                  //         profileId: profileId,
                  //         category: Constants.categoryType.CATEGORY_FESTIVAL,
                  //         id: item.id,
                  //       },
                  //     });
                  //   }
                  // }}
                />
                {isLoadingCastle && <ActivityIndicator />}
              </div>
            )}

            {/* Resorts */}
            {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
              (i) => i === "Resorts"
            ) && (
              <div className="relative">
                <div className="flex flex-row sm:items-center justify-between px-16">
                  <div className="flex sm:flex-row flex-col sm:items-center">
                    <div
                      className={`text-2xl font-bold ${getClass(
                        "text-white",
                        true
                      )} sm:mr-7 sm:mb-0 mb-2`}>
                      RESORTS
                    </div>
                    {/* <div className="flex flex-row items-center">
                      <div
                        className={`text-base font-medium border-b-2 cursor-pointer pb-5px mr-7 ${
                          venueBeachfulSort ===
                          Constants.sortByAction.SORT_BY_RECOMMENDED
                            ? "border-white text-white"
                            : "border-transparent text-white3"
                        }`}
                        onClick={() =>
                          setVenueBeachfulSort(
                            Constants.sortByAction.SORT_BY_RECOMMENDED
                          )
                        }>
                        Recommended
                      </div>
                    </div>
                    <div className="flex flex-row items-center">
                      <div
                        className={`text-base font-medium border-b-2 cursor-pointer pb-5px mr-7 ${
                          venueBeachfulSort ===
                          Constants.sortByAction.SORT_BY_TRENDING
                            ? "border-white text-white"
                            : "border-transparent text-white3"
                        }`}
                        onClick={() =>
                          setVenueBeachfulSort(
                            Constants.sortByAction.SORT_BY_TRENDING
                          )
                        }>
                        Trending
                      </div>
                    </div> */}
                  </div>
                  <Link href="/explore/resorts">
                    <a
                      className={`text-xl font-bold ${getClass(
                        "text-white",
                        true
                      )} uppercase cursor-pointer`}>
                      Discover
                    </a>
                  </Link>
                </div>
                {resortList.length > 4 && (
                  <div className="absolute z-10 top-40 w-full flex flex-row justify-between">
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        if (pageResort - 1 >= 0) {
                          pageResort--;
                        }
                        const newIndex = pageResort * 4;
                        flatListResortRef?.current?.scrollToIndex({
                          animated: true,
                          index: newIndex,
                        });
                      }}>
                      <img
                        src={`/icons/${
                          process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "LuxResort Club" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "AparthotelClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
                            ? process.env.NEXT_PUBLIC_APP_NAME?.replace(
                                " ",
                                ""
                              )?.toLowerCase() + "/"
                            : ""
                        }ic_arrow_left.png`}
                        width="40"
                        height="40"
                        alt="arrow"
                      />
                    </div>
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        if (
                          pageResort + 1 <
                          Math.round(resortList.length / 4)
                        ) {
                          pageResort++;
                        }
                        const newIndex = pageResort * 4;
                        flatListResortRef?.current?.scrollToIndex({
                          animated: true,
                          index: newIndex,
                        });
                      }}>
                      <img
                        src={`/icons/${
                          process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "LuxResort Club" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "AparthotelClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
                            ? process.env.NEXT_PUBLIC_APP_NAME?.replace(
                                " ",
                                ""
                              )?.toLowerCase() + "/"
                            : ""
                        }ic_arrow_right.png`}
                        width="40"
                        height="40"
                        alt="arrow"
                      />
                    </div>
                  </div>
                )}
                <CardList
                  ref={flatListResortRef}
                  horizontal={true}
                  items={resortList}
                  category={Constants.categoryType.CATEGORY_RESORT}
                  style={{ marginTop: 30, paddingHorizontal: 54 }}
                  withoutBadge={true}
                  // onItemAction={({ type, item }) => {
                  //   if (type == Constants.actionType.ACTION_VIEW) {
                  //     var profileId = item.profileId || item.id;
                  //     router.push({
                  //       pathname: "/profile/details",
                  //       query: {
                  //         profileId: profileId,
                  //         category: Constants.categoryType.CATEGORY_FESTIVAL,
                  //         id: item.id,
                  //       },
                  //     });
                  //   }
                  // }}
                />
                {isLoadingResort && <ActivityIndicator />}
              </div>
            )}

            {/* Aparthotels */}
            {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
              (i) => i === "Aparthotels"
            ) && (
              <div className="relative">
                <div className="flex flex-row sm:items-center justify-between px-16">
                  <div className="flex sm:flex-row flex-col sm:items-center">
                    <div
                      className={`text-2xl font-bold ${getClass(
                        "text-white",
                        true
                      )} sm:mr-7 sm:mb-0 mb-2`}>
                      APARTHOTELS
                    </div>
                    {/* <div className="flex flex-row items-center">
                      <div
                        className={`text-base font-medium border-b-2 cursor-pointer pb-5px mr-7 ${
                          venueBeachfulSort ===
                          Constants.sortByAction.SORT_BY_RECOMMENDED
                            ? "border-white text-white"
                            : "border-transparent text-white3"
                        }`}
                        onClick={() =>
                          setVenueBeachfulSort(
                            Constants.sortByAction.SORT_BY_RECOMMENDED
                          )
                        }>
                        Recommended
                      </div>
                    </div>
                    <div className="flex flex-row items-center">
                      <div
                        className={`text-base font-medium border-b-2 cursor-pointer pb-5px mr-7 ${
                          venueBeachfulSort ===
                          Constants.sortByAction.SORT_BY_TRENDING
                            ? "border-white text-white"
                            : "border-transparent text-white3"
                        }`}
                        onClick={() =>
                          setVenueBeachfulSort(
                            Constants.sortByAction.SORT_BY_TRENDING
                          )
                        }>
                        Trending
                      </div>
                    </div> */}
                  </div>
                  <Link href="/explore/aparthotels">
                    <a
                      className={`text-xl font-bold ${getClass(
                        "text-white",
                        true
                      )} uppercase cursor-pointer`}>
                      Discover
                    </a>
                  </Link>
                </div>
                {aparthotelList.length > 4 && (
                  <div className="absolute z-10 top-40 w-full flex flex-row justify-between">
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        if (pageAparthotel - 1 >= 0) {
                          pageAparthotel--;
                        }
                        const newIndex = pageAparthotel * 4;
                        flatListAparthotelRef?.current?.scrollToIndex({
                          animated: true,
                          index: newIndex,
                        });
                      }}>
                      <img
                        src={`/icons/${
                          process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "LuxResort Club" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "AparthotelClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
                            ? process.env.NEXT_PUBLIC_APP_NAME?.replace(
                                " ",
                                ""
                              )?.toLowerCase() + "/"
                            : ""
                        }ic_arrow_left.png`}
                        width="40"
                        height="40"
                        alt="arrow"
                      />
                    </div>
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        if (
                          pageAparthotel + 1 <
                          Math.round(aparthotelList.length / 4)
                        ) {
                          pageAparthotel++;
                        }
                        const newIndex = pageAparthotel * 4;
                        flatListAparthotelRef?.current?.scrollToIndex({
                          animated: true,
                          index: newIndex,
                        });
                      }}>
                      <img
                        src={`/icons/${
                          process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "LuxResort Club" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "AparthotelClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
                            ? process.env.NEXT_PUBLIC_APP_NAME?.replace(
                                " ",
                                ""
                              )?.toLowerCase() + "/"
                            : ""
                        }ic_arrow_right.png`}
                        width="40"
                        height="40"
                        alt="arrow"
                      />
                    </div>
                  </div>
                )}
                <CardList
                  ref={flatListAparthotelRef}
                  horizontal={true}
                  items={aparthotelList}
                  category={Constants.categoryType.CATEGORY_APARTHOTEL}
                  style={{ marginTop: 30, paddingHorizontal: 54 }}
                  withoutBadge={true}
                  // onItemAction={({ type, item }) => {
                  //   if (type == Constants.actionType.ACTION_VIEW) {
                  //     var profileId = item.profileId || item.id;
                  //     router.push({
                  //       pathname: "/profile/details",
                  //       query: {
                  //         profileId: profileId,
                  //         category: Constants.categoryType.CATEGORY_FESTIVAL,
                  //         id: item.id,
                  //       },
                  //     });
                  //   }
                  // }}
                />
                {isLoadingAparthotel && <ActivityIndicator />}
              </div>
            )}

            {/* Venue Cocktail */}
            {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
              (i) => i === "Venue Cocktail"
            ) && (
              <div className="relative">
                <div className="flex flex-row sm:items-center justify-between px-16">
                  <div className="flex sm:flex-row flex-col sm:items-center">
                    <div
                      className={`text-2xl font-bold ${getClass(
                        "text-white",
                        true
                      )} sm:mr-7 sm:mb-0 mb-2`}>
                      BARS
                    </div>
                    {/* <div className="flex flex-row items-center">
                      <div
                        className={`text-base font-medium border-b-2 cursor-pointer pb-5px mr-7 ${
                          venueCocktailSort ===
                          Constants.sortByAction.SORT_BY_RECOMMENDED
                            ? "border-white text-white"
                            : "border-transparent text-white3"
                        }`}
                        onClick={() =>
                          setVenueCocktailSort(
                            Constants.sortByAction.SORT_BY_RECOMMENDED
                          )
                        }>
                        Recommended
                      </div>
                    </div>
                    <div className="flex flex-row items-center">
                      <div
                        className={`text-base font-medium border-b-2 cursor-pointer pb-5px mr-7 ${
                          venueCocktailSort ===
                          Constants.sortByAction.SORT_BY_TRENDING
                            ? "border-white text-white"
                            : "border-transparent text-white3"
                        }`}
                        onClick={() =>
                          setVenueCocktailSort(
                            Constants.sortByAction.SORT_BY_TRENDING
                          )
                        }>
                        Trending
                      </div>
                    </div> */}
                  </div>
                  <Link href="/explore/bars">
                    <a
                      className={`text-xl font-bold ${getClass(
                        "text-white",
                        true
                      )} uppercase cursor-pointer`}>
                      Discover
                    </a>
                  </Link>
                </div>
                {venueCocktailList.length > 4 && (
                  <div className="absolute z-10 top-40 w-full flex flex-row justify-between">
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        if (pageVenueCocktail - 1 >= 0) {
                          pageVenueCocktail--;
                        }
                        const newIndex = pageVenueCocktail * 4;
                        flatListVenueCocktailRef?.current?.scrollToIndex({
                          animated: true,
                          index: newIndex,
                        });
                      }}>
                      <img
                        src={`/icons/${
                          process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "LuxResort Club" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "AparthotelClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
                            ? process.env.NEXT_PUBLIC_APP_NAME?.replace(
                                " ",
                                ""
                              )?.toLowerCase() + "/"
                            : ""
                        }ic_arrow_left.png`}
                        width="40"
                        height="40"
                        alt="arrow"
                      />
                    </div>
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        if (
                          pageVenueCocktail + 1 <
                          Math.round(venueCocktailList.length / 4)
                        ) {
                          pageVenueCocktail++;
                        }
                        const newIndex = pageVenueCocktail * 4;
                        flatListVenueCocktailRef?.current?.scrollToIndex({
                          animated: true,
                          index: newIndex,
                        });
                      }}>
                      <img
                        src={`/icons/${
                          process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "LuxResort Club" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "AparthotelClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
                            ? process.env.NEXT_PUBLIC_APP_NAME?.replace(
                                " ",
                                ""
                              )?.toLowerCase() + "/"
                            : ""
                        }ic_arrow_right.png`}
                        width="40"
                        height="40"
                        alt="arrow"
                      />
                    </div>
                  </div>
                )}
                <CardList
                  ref={flatListVenueCocktailRef}
                  horizontal={true}
                  items={venueCocktailList}
                  category={Constants.categoryType.CATEGORY_VENUE_COCKTAIL}
                  style={{ marginTop: 30, paddingHorizontal: 54 }}
                  withoutBadge={true}
                  // onItemAction={({ type, item }) => {
                  //   if (type == Constants.actionType.ACTION_VIEW) {
                  //     var profileId = item.profileId || item.id;
                  //     router.push({
                  //       pathname: "/profile/details",
                  //       query: {
                  //         profileId: profileId,
                  //         category: Constants.categoryType.CATEGORY_FESTIVAL,
                  //         id: item.id,
                  //       },
                  //     });
                  //   }
                  // }}
                />
                {isLoadingVenueCocktail && <ActivityIndicator />}
              </div>
            )}

            {/* Festival */}
            {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
              (i) => i === "Festivals"
            ) && (
              <div className="relative">
                <div className="flex flex-row sm:items-center justify-between px-16 mt-14">
                  <div className="flex sm:flex-row flex-col sm:items-center">
                    <div
                      className={`text-2xl font-bold ${getClass(
                        "text-white",
                        true
                      )} sm:mr-7 sm:mb-0 mb-2`}>
                      FESTIVALS
                    </div>
                    {/* <div className="flex flex-row items-center">
                      <div
                        className={`text-base font-medium border-b-2 cursor-pointer pb-5px mr-7 ${
                          festivalSort ===
                          Constants.sortByAction.SORT_BY_POPULARITY
                            ? "border-white text-white"
                            : "border-transparent text-white3"
                        }`}
                        onClick={() =>
                          setFestivalSort(
                            Constants.sortByAction.SORT_BY_POPULARITY
                          )
                        }>
                        Popular
                      </div>
                      <div
                        className={`text-base font-medium border-b-2 cursor-pointer pb-5px ${
                          festivalSort ===
                          Constants.sortByAction.SORT_BY_TRENDING
                            ? "border-white text-white"
                            : "border-transparent text-white3"
                        }`}
                        onClick={() =>
                          setFestivalSort(
                            Constants.sortByAction.SORT_BY_TRENDING
                          )
                        }>
                        Trending
                      </div>
                    </div> */}
                  </div>
                  <Link href="/explore/festivals">
                    <a
                      className={`text-xl font-bold ${getClass(
                        "text-white",
                        true
                      )} uppercase cursor-pointer`}>
                      Discover
                    </a>
                  </Link>
                </div>
                {festivalList.length > 4 && (
                  <div className="absolute z-10 top-40 w-full flex flex-row justify-between">
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        if (pageFestival - 1 >= 0) {
                          pageFestival--;
                        }
                        const newIndex = pageFestival * 4;
                        flatListFestivalRef?.current?.scrollToIndex({
                          animated: true,
                          index: newIndex,
                        });
                      }}>
                      <img
                        src={`/icons/${
                          process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "LuxResort Club" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "AparthotelClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
                            ? process.env.NEXT_PUBLIC_APP_NAME?.replace(
                                " ",
                                ""
                              )?.toLowerCase() + "/"
                            : ""
                        }ic_arrow_left.png`}
                        width="40"
                        height="40"
                        alt="arrow"
                      />
                    </div>
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        if (
                          pageFestival + 1 <
                          Math.round(festivalList.length / 4)
                        ) {
                          pageFestival++;
                        }
                        const newIndex = pageFestival * 4;
                        flatListFestivalRef?.current?.scrollToIndex({
                          animated: true,
                          index: newIndex,
                        });
                      }}>
                      <img
                        src={`/icons/${
                          process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "LuxResort Club" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "AparthotelClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
                            ? process.env.NEXT_PUBLIC_APP_NAME?.replace(
                                " ",
                                ""
                              )?.toLowerCase() + "/"
                            : ""
                        }ic_arrow_right.png`}
                        width="40"
                        height="40"
                        alt="arrow"
                      />
                    </div>
                  </div>
                )}
                <CardList
                  ref={flatListFestivalRef}
                  horizontal={true}
                  items={festivalList}
                  category={Constants.categoryType.CATEGORY_FESTIVAL}
                  style={{ marginTop: 30, paddingHorizontal: 54 }}
                  withoutBadge={true}
                  // onItemAction={({ type, item }) => {
                  //   if (type == Constants.actionType.ACTION_VIEW) {
                  //     var profileId = item.profileId || item.id;
                  //     router.push({
                  //       pathname: "/profile/details",
                  //       query: {
                  //         profileId: profileId,
                  //         category: Constants.categoryType.CATEGORY_FESTIVAL,
                  //         id: item.id,
                  //       },
                  //     });
                  //   }
                  // }}
                />
                {isLoadingFestival && <ActivityIndicator />}
              </div>
            )}

            {/* Event */}
            {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
              (i) => i === "Events"
            ) && (
              <div className="relative">
                <div className="flex flex-row sm:items-center justify-between px-16 mt-14">
                  <div className="flex sm:flex-row flex-col sm:items-center">
                    <div
                      className={`text-2xl font-bold ${getClass(
                        "text-white",
                        true
                      )} sm:mr-7 sm:mb-0 mb-2`}>
                      EVENTS
                    </div>
                    {/* <div className="flex flex-row items-center">
                      <div
                        className={`text-base font-medium border-b-2 cursor-pointer pb-5px mr-7 ${
                          eventSort ===
                          Constants.sortByAction.SORT_BY_POPULARITY
                            ? "border-white text-white"
                            : "border-transparent text-white3"
                        }`}
                        onClick={() =>
                          setEventSort(
                            Constants.sortByAction.SORT_BY_POPULARITY
                          )
                        }>
                        Popular
                      </div>
                      <div
                        className={`text-base font-medium border-b-2 cursor-pointer pb-5px ${
                          eventSort === Constants.sortByAction.SORT_BY_TRENDING
                            ? "border-white text-white"
                            : "border-transparent text-white3"
                        }`}
                        onClick={() =>
                          setEventSort(Constants.sortByAction.SORT_BY_TRENDING)
                        }>
                        Trending
                      </div>
                    </div> */}
                  </div>
                  <Link href="/explore/events">
                    <a
                      className={`text-xl font-bold ${getClass(
                        "text-white",
                        true
                      )} uppercase cursor-pointer`}>
                      Discover
                    </a>
                  </Link>
                </div>
                {eventList.length > 4 && (
                  <div className="absolute z-10 top-40 w-full flex flex-row justify-between">
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        if (pageEvent - 1 >= 0) {
                          pageEvent--;
                        }
                        const newIndex = pageEvent * 4;
                        flatListEventRef?.current?.scrollToIndex({
                          animated: true,
                          index: newIndex,
                        });
                      }}>
                      <img
                        src={`/icons/${
                          process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "LuxResort Club" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "AparthotelClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
                            ? process.env.NEXT_PUBLIC_APP_NAME?.replace(
                                " ",
                                ""
                              )?.toLowerCase() + "/"
                            : ""
                        }ic_arrow_left.png`}
                        width="40"
                        height="40"
                        alt="arrow"
                      />
                    </div>
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        if (pageEvent + 1 < Math.round(eventList.length / 4)) {
                          pageEvent++;
                        }
                        const newIndex = pageEvent * 4;
                        flatListEventRef?.current?.scrollToIndex({
                          animated: true,
                          index: newIndex,
                        });
                      }}>
                      <img
                        src={`/icons/${
                          process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "LuxResort Club" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "AparthotelClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
                            ? process.env.NEXT_PUBLIC_APP_NAME?.replace(
                                " ",
                                ""
                              )?.toLowerCase() + "/"
                            : ""
                        }ic_arrow_right.png`}
                        width="40"
                        height="40"
                        alt="arrow"
                      />
                    </div>
                  </div>
                )}
                <CardList
                  ref={flatListEventRef}
                  horizontal={true}
                  items={eventList}
                  category={Constants.categoryType.CATEGORY_EVENT}
                  style={{ marginTop: 30, paddingHorizontal: 54 }}
                  withoutBadge={true}
                  // onItemAction={({ type, item }) => {
                  //   if (type == Constants.actionType.ACTION_VIEW) {
                  //     var profileId = item.profileId || item.id;
                  //     router.push({
                  //       pathname: "/profile/details",
                  //       query: {
                  //         profileId: profileId,
                  //         category: Constants.categoryType.CATEGORY_FESTIVAL,
                  //         id: item.id,
                  //       },
                  //     });
                  //   }
                  // }}
                />
                {isLoadingEvent && <ActivityIndicator />}
              </div>
            )}

            {/* Artist */}
            {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
              (i) => i === "Artists"
            ) && (
              <div className="relative">
                <div className="flex flex-row sm:items-center justify-between px-16 mt-14">
                  <div className="flex sm:flex-row flex-col sm:items-center">
                    <div
                      className={`text-2xl font-bold ${getClass(
                        "text-white",
                        true
                      )} sm:mr-7 sm:mb-0 mb-2`}>
                      ARTISTS
                    </div>
                    {/* <div className="flex flex-row items-center">
                      <div
                        className={`text-base font-medium border-b-2 cursor-pointer pb-5px mr-7 ${
                          artistSort ===
                          Constants.sortByAction.SORT_BY_POPULARITY
                            ? "border-white text-white"
                            : "border-transparent text-white3"
                        }`}
                        onClick={() =>
                          setArtistSort(
                            Constants.sortByAction.SORT_BY_POPULARITY
                          )
                        }>
                        Popular
                      </div>
                      <div
                        className={`text-base font-medium border-b-2 cursor-pointer pb-5px ${
                          artistSort === Constants.sortByAction.SORT_BY_TRENDING
                            ? "border-white text-white"
                            : "border-transparent text-white3"
                        }`}
                        onClick={() =>
                          setArtistSort(Constants.sortByAction.SORT_BY_TRENDING)
                        }>
                        Trending
                      </div>
                    </div> */}
                  </div>
                  <Link href="/explore/artists">
                    <a
                      className={`text-xl font-bold ${getClass(
                        "text-white",
                        true
                      )} uppercase cursor-pointer`}>
                      Discover
                    </a>
                  </Link>
                </div>
                {artistList.length > 6 && (
                  <div className="absolute z-10 top-40 w-full flex flex-row justify-between">
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        if (pageArtist - 1 >= 0) {
                          pageArtist--;
                        }
                        const newIndex = pageArtist * 6;
                        flatListArtistRef?.current?.scrollToIndex({
                          animated: true,
                          index: newIndex,
                        });
                      }}>
                      <img
                        src={`/icons/${
                          process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "LuxResort Club" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "AparthotelClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
                            ? process.env.NEXT_PUBLIC_APP_NAME?.replace(
                                " ",
                                ""
                              )?.toLowerCase() + "/"
                            : ""
                        }ic_arrow_left.png`}
                        width="40"
                        height="40"
                        alt="arrow"
                      />
                    </div>
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        if (
                          pageArtist + 1 <
                          Math.round(artistList.length / 6)
                        ) {
                          pageArtist++;
                        }
                        const newIndex = pageArtist * 6;
                        flatListArtistRef?.current?.scrollToIndex({
                          animated: true,
                          index: newIndex,
                        });
                      }}>
                      <img
                        src={`/icons/${
                          process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "LuxResort Club" ||
                          process.env.NEXT_PUBLIC_APP_NAME ===
                            "AparthotelClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
                          process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
                            ? process.env.NEXT_PUBLIC_APP_NAME?.replace(
                                " ",
                                ""
                              )?.toLowerCase() + "/"
                            : ""
                        }ic_arrow_right.png`}
                        width="40"
                        height="40"
                        alt="arrow"
                      />
                    </div>
                  </div>
                )}
                <CardList
                  ref={flatListArtistRef}
                  horizontal={true}
                  items={artistList}
                  category={Constants.categoryType.CATEGORY_ARTIST}
                  style={{ marginTop: 30, paddingHorizontal: 54 }}
                  withoutBadge={true}
                  // onItemAction={({ type, item }) => {
                  //   if (type == Constants.actionType.ACTION_VIEW) {
                  //     var profileId = item.profileId || item.id;
                  //     router.push({
                  //       pathname: "/profile/details",
                  //       query: {
                  //         profileId: profileId,
                  //         category: Constants.categoryType.CATEGORY_ARTIST,
                  //         id: item.id,
                  //       },
                  //     });
                  //   }
                  // }}
                />
                {isLoadingArtist && <ActivityIndicator />}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* App Section Desktop */}
      {/* <div
        style={{
          backgroundImage: "url(/images/landing_bg_new.jpg)",
          backgroundSize: "cover",
          backgroundPosition: "bottom center",
        }}
        className="sm:block hidden relative">
        <div className="wrapper mx-auto relative md:h-800px h-auto md:py-87px sm:py-20 py-10">
          <div className="flex flex-col justify-center items-center h-full">
            <div className="sm:text-40px flex justify-center items-center h-49px text-lg font-bold text-white text-center drop-shadow-3xl sm:mb-51px mb-6">
              {t("key_web_home_app_1")}
            </div>
            <CImage
              source="/images/app_screenshot_new_2.png"
              width={747}
              height={356}
              layout="fixed"
              alt="screenshot app"
            />
            <div className="flex flex-row items-center mb-24px mt-54px">
              <a
                href="https://play.google.com/store/apps/details?id=com.soundclub"
                className="-ml-10 pr-8"
                target="_blank"
                rel="noreferrer"
                style={{ height: 55 }}>
                <CImage
                  source="/images/download-googleplay.svg"
                  width={197}
                  height={55}
                  layout="fixed"
                  alt="playstore"
                />
              </a>
              <a
                href="https://apps.apple.com/us/app/soundclub-discover-festivals/id1625045664"
                target="_blank"
                rel="noreferrer"
                style={{ height: 51 }}>
                <CImage
                  source="/images/download-appstore.svg"
                  width={152}
                  height={51}
                  layout="fixed"
                  alt="appstore"
                />
              </a>
            </div>
            <div className="sm:text-40px flex justify-center items-center h-49px text-lg font-bold text-white text-center drop-shadow-3xl">
              {t("key_web_home_app_4")}
            </div>
          </div>
        </div>
      </div> */}

      <div className="sm:block hidden">
        <Footer />
      </div>
    </div>
  );
};

export default Landing;
